(function () {
    'use strict';

    angular.module('myApp.userAdmin')
        .controller('userAdminCtrl', UserAdminController);
    UserAdminController.$inject = ['$rootScope', 'AccountProfileService', 'all_users'];

    function UserAdminController($rootScope, AccountProfileService, all_users) {
        var vm = this;

        vm.currentView = "overview";
        vm.message = "";
        vm.newUser = {
            js_start_date: new Date(),
            js_end_date: null,
            is_support_user: false
        };
        vm.searchUser = '';
        vm.sortReverse = false;
        vm.sortType = 'name';

        vm.updateAccount = updateAccount;
        vm.changeActiveDate = changeActiveDate;
        vm.changeEndDate = changeEndDate;
        vm.createAccount = createAccount;
        vm.initialDateInput = initialDateInput;

        activate();
        // function definitions
        function activate() {
            vm.allUsers = all_users;
            $rootScope.currentNaviAction = 'useradmin';
        }
        
        function changeActiveDate(event, user) {
            var date_string = convertJsDateToString(user.new_js_active_date);
            //Enter key is 13
            if (event.which === 13 && date_string) {
                if (user.active_at != date_string) {
                    user.active_at = date_string;
                    AccountProfileService.updateAccountProfile(user.id, user)
                        .then(function (response) {
                            vm.message = "success update";
                            user.active_at = response.active_at;
                            initialDateInput(user);
                        })
                        .catch(function (response) {
                            console.log(response);
                        });
                }
                user.edit_date = '';
                //Escape key is 27
            } else if (event.which === 27) {
                initialDateInput(user);
                user.edit_date = '';
            }
        }
        
        function changeEndDate(event, user) {
            var date_string = convertJsDateToString(user.new_js_end_date);
            //Enter key is 13
            if (event.which === 13 && (date_string || user.new_js_end_date === null)) {
                if (user.end_at != date_string) {
                    user.end_at = date_string;
                    AccountProfileService.updateAccountProfile(user.id, user)
                        .then(function (response) {
                            vm.message = "success update";
                            user.end_at = response.end_at;
                            initialDateInput(user);
                        })
                        .catch(function (response) {
                            console.log(response);
                        });
                }
                user.edit_date = '';
                //Escape key is 27
            } else if (event.which === 27) {
                initialDateInput(user);
                user.edit_date = '';
            }
        }

        function updateAccount(user) {
            AccountProfileService.updateAccountProfile(user.id, user)
                .then(function (response) {
                    vm.message = "success update";
                    user = response;
                })
                .catch(function (response) {
                    console.log(response);
                });
        }

        function createAccount() {
            vm.newUser.active_at = convertJsDateToString(vm.newUser.js_start_date);
            vm.newUser.end_at = convertJsDateToString(vm.newUser.js_end_date);
            vm.newUser.is_active = true;
            vm.newUser.password = null;
            AccountProfileService.createNewUser(vm.newUser)
                .then(function (response) {
                    vm.message = "success update";
                    vm.newUser = {
                        js_start_date: new Date(),
                        js_end_date: null,
                        is_support_user: false
                    };
                    vm.allUsers.push(response);
                })
                .catch(function (response) {
                    console.log(response);
                });
        }

        function convertJsDateToString(js_data) {
            if (js_data) {
                var day = ("0" + js_data.getDate()).slice(-2);
                var monthIndex = ("0" + (js_data.getMonth() + 1)).slice(-2);
                var year = js_data.getFullYear();
                var data_string = year + '-' + monthIndex + '-' + day + 'T00:00:00Z';
                return data_string;
            }
            return null;
        }

        function initialDateInput(user) {
            var date_string = '';
            if (user.end_at) {
                date_string = user.end_at.split('T')[0];
                user.endAtDisplay = date_string;
                user.new_js_end_date = new Date(date_string);
            } else {
                user.endAtDisplay = 'Unlimited';
                user.new_js_end_date = null;
            }
            if (user.active_at) {
                date_string = user.active_at.split('T')[0];
                user.activeAtDisplay = date_string;
                user.new_js_active_date = new Date(date_string);
            } else {
                user.activeAtDisplay = 'Undefined';
                user.new_js_active_date = new Date(0);
            }
        }
    }
})();

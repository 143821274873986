(function () {
    'use strict';

    angular.module('myApp.projectdetails')
        .controller('ProjectsDetailsController', ProjectsDetailsController);

    ProjectsDetailsController.$inject = [
        '$location',
        '$q',
        '$rootScope',
        '$stateParams',
        'all_resources',
        'all_multi_level_bundles',
        'CobaResourceService',
        'MultiLevelBundleService',
        'project_info',
        'UiEffectService',
        'FlashService'
    ];
    function ProjectsDetailsController(
        $location,
        $q,
        $rootScope,
        $stateParams,
        all_resources,
        all_multi_level_bundles,
        CobaResourceService,
        MultiLevelBundleService,
        project_info,
        UiEffectService,
        FlashService) {

        var vm = this;

        vm.copy_in_edit_resource_or_bundle = null;
        vm.in_edit_focus = -1;
        vm.newCobaFiles = null;
        vm.permissions = null;
        vm.selectedUpdateCobaFiles = null;
        vm.updateSuccess = false;
        vm.searchCobaFile = '';
        vm.selectedFiles = [];
        vm.showChangeConfirmDialog = false;
        vm.showDeleteDialog = false;
        vm.ResourceToRemoveFromBundle = [];
        //vm.sortReverse = false;
        //vm.sortType = 'name';

        vm.addNewBundle = addNewBundle;
        vm.changeCobaResourceName = changeCobaResourceName;
        vm.confirmEditBundle = confirmEditBundle;
        vm.createNewCobaResource = createNewCobaResource;
        vm.deleteSelectedCobaResourcesAndMultiLevelBundles = deleteSelectedCobaResourcesAndMultiLevelBundles;
        vm.editMultiLevelBundle = editMultiLevelBundle;
        vm.getVersionDisplayName = getVersionDisplayName;
        vm.goToDetails = goToDetails;
        vm.removeFileFromNewCobaFiles = removeFileFromNewCobaFiles;
        vm.resetSelection = resetSelection;
        vm.selectExistingResourceToInEditBundle = selectExistingResourceToInEditBundle;
        vm.switchEditMode = switchEditMode;
        vm.toggleSelection = toggleSelection;
        vm.undoEditCobaResource = undoEditCobaResource;
        vm.undoEditMultiLevelBundle = undoEditMultiLevelBundle;
        vm.updateNewVersionOfCobaResource = updateNewVersionOfCobaResource;

        activate();

        function activate() {
            vm.projectname = project_info.name;
            vm.projectId = $stateParams.projectid;
            vm.cobaResources = all_resources;
            vm.multiLevelBundles = all_multi_level_bundles;
            vm.permissions = project_info.permissions;
            $rootScope.currentNaviAction = 'projects';
        }

        function addNewBundle() {
            var resources = [];
            angular.forEach(vm.cobaResources, function (resource) {
                if (vm.selectedFiles.indexOf(resource.id) != -1) {
                    var resource = {"resource_id": resource.id};
                    resources.push(resource);
                }
            });
            if (resources.length < 2) {
                $rootScope.critical_errors = "Please select at least two CoBa resources to create a multi level bundle.";
                return
            }
            var new_bundle = {'id': 'new_bundle', 'resources': resources, "name": "", "project": vm.projectId};
            vm.multiLevelBundles.splice(0, 0, new_bundle);
            new_bundle.show_details = true;
            vm.selectedFiles.length = 0;
            switchEditMode(new_bundle);
            new_bundle.resources = [];
        }

        function changeCobaResourceName(cobaresource) {
            vm.updateSuccess = false;
            var new_resource_name = cobaresource.resource_name.trim();
            if (new_resource_name) {
                CobaResourceService.updateCobaResourceInformation(
                    new_resource_name, cobaresource.id, vm.projectId)
                    .then(function (data, status, headers, config) {
                        vm.in_edit_focus = -1;
                        vm.updateSuccess = true;
                    }).catch(handleUpdateError);
            } else {
                $rootScope.critical_errors = "Name can not be empty!";
            }
        }

        function createNewCobaResource(newFile) {
            var file = newFile;
            CobaResourceService.uploadCobaToServer(file.custom_name, file, vm.projectId)
                .then(function (data, status, headers, config) {
                    vm.cobaResources.push(data);
                    vm.removeFileFromNewCobaFiles(newFile);
                })
                .catch(function (e) {
                    if (e.data.exception_message == "{u'non_field_errors': [u'The fields project, resource_name must make a unique set.']}") {
                        $rootScope.critical_errors = "There was an error uploading the CoBa resource. The name must be unique.";
                    }
                    else if(e.data.exception_message == "{u'non_field_errors': [u'Uploaded file not a coba file. (" + file.custom_name + ")']}") {
                        $rootScope.critical_errors = "Uploaded file not a coba file. (" + file.custom_name + ")";
                    }
                });
        }

        function confirmEditBundle(bundle) {
            var new_resources_ids = [];
            angular.forEach(vm.copy_in_edit_resource_or_bundle.resources, function (resource) {
                if (!resource.markedToBeDeleted) {
                    new_resources_ids.push(resource.resource_id);
                }
            });
            var missing_original_resources_in_new_resources = [];
            angular.forEach(bundle.resources, function (resource) {
                if (new_resources_ids.indexOf(resource.resource_id) == -1){
                    missing_original_resources_in_new_resources.push(resource);
                }
            });
            vm.copy_in_edit_resource_or_bundle.new_name = bundle.name;
            if (missing_original_resources_in_new_resources.length>0) {
                vm.ResourceToRemoveFromBundle = missing_original_resources_in_new_resources;
                vm.showChangeConfirmDialog = true;
            } else {
                vm.editMultiLevelBundle();
            }
        }

        function deleteSelectedCobaResourcesAndMultiLevelBundles() {
            FlashService.clearFlashMessage();
            var resource_promises_to_remove = [];
            var bundle_promises_to_remove = [];
            var index_in_selectedFiles = -1;
            angular.forEach(vm.multiLevelBundles, function (bundle) {
                var bundle_id = bundle.id;
                index_in_selectedFiles = vm.selectedFiles.indexOf(bundle_id);
                if (index_in_selectedFiles > -1) {
                    vm.selectedFiles.splice(index_in_selectedFiles, 1);
                    bundle_promises_to_remove.push(MultiLevelBundleService.deleteMultiLevelBundle(bundle_id)
                        .then(function (data) {
                        }).catch(function (data, status, headers, config) {
                            vm.selectedFiles.push(bundle_id);
                            FlashService.Error(
                                'Selected Bundle ' + bundle.name + ' can not be deleted: ' + data.data.exception_message);
                        }));
                }
            });
            angular.forEach(vm.cobaResources, function (resource) {
                var resource_id = resource.id;
                index_in_selectedFiles = vm.selectedFiles.indexOf(resource_id);
                if (index_in_selectedFiles != -1) {
                    vm.selectedFiles.splice(index_in_selectedFiles, 1);
                    resource_promises_to_remove.push(CobaResourceService.deleteCobaFileFromServer(resource_id)
                        .then(function (data) {
                        }).catch(function (data, status, headers, config) {
                            vm.selectedFiles.push(resource_id);
                            FlashService.Error(
                                'Selected Resource ' + resource.resource_name + ' can not be deleted: ' + data.data.exception_message);
                        }));
                }
            });
            vm.selectedFiles.length = 0;
            //delete first selected bundles
            $q.all(bundle_promises_to_remove)
                .then(function (data) {
                    synchronizeMultiLevelBundle(vm.multiLevelBundles);
                })
                .catch(function (data, status, headers, config) {
                    synchronizeMultiLevelBundle(vm.multiLevelBundles);
            });
            //then delete selected resources
            $q.all(resource_promises_to_remove)
                .then(function (data) {
                    synchronizeCoBaResources(vm.cobaResources);
                })
                .catch(function (data, status, headers, config) {
                    synchronizeCoBaResources(vm.cobaResources);
            });
        }

        function editMultiLevelBundle() {
            vm.updateSuccess = false;
            FlashService.clearFlashMessage();
            var new_name = vm.copy_in_edit_resource_or_bundle.new_name.trim();
            if (new_name) {
                var remain_resources = [];
                angular.forEach(vm.copy_in_edit_resource_or_bundle.resources, function(resource){
                    if (!resource.markedToBeDeleted) {
                        remain_resources.push(resource);
                    }
                });
                if (vm.copy_in_edit_resource_or_bundle.id != "new_bundle") {
                    MultiLevelBundleService.updateMultiLevelBundle(
                        new_name, vm.copy_in_edit_resource_or_bundle.id, vm.projectId, remain_resources)
                        .then(function (data, status, headers, config) {
                            vm.in_edit_focus = -1;
                            vm.updateSuccess = true;
                            synchronizeMultiLevelBundle(vm.multiLevelBundles);
                        }).catch(handleUpdateError);
                } else {
                    MultiLevelBundleService.createMultiLevelBundle(new_name, vm.projectId, remain_resources)
                        .then(function (data, status, headers, config) {
                            vm.in_edit_focus = -1;
                            synchronizeMultiLevelBundle(vm.multiLevelBundles);
                        })
                        .catch(handleUpdateError);
                }
            } else {
                $rootScope.critical_errors = "Please enter a name for the multi level bundle.";
            }
        }

        function getVersionDisplayName(resource_version_id) {
            if(resource_version_id == null || resource_version_id == "") {
                return "latest";
            }
            else {
                return resource_version_id;
            }
        }

        function goToDetails(cobaresource) {
            $location.path('/projectdetails/' + vm.projectname + '/' + vm.projectId + '/resourcedetails/' +cobaresource.id);
        }

        function handleUpdateError(data) {
            if (data.data.exception_message.includes("not found")) {
                $rootScope.critical_errors = "Resource not found";
            } else if (data.data.exception_message.includes("unique")){
                $rootScope.critical_errors = "Same name exists already";
            } else {
                $rootScope.critical_errors = data.data.exception_message;
            }
        }

        function removeFileFromNewCobaFiles(newFile) {
            var indexToRemove = -1;
            for (var i = 0; i < vm.newCobaFiles.length; i++) {
                if (newFile.name == vm.newCobaFiles[i].name) {
                    indexToRemove = i;
                    break;
                }
            }
            vm.newCobaFiles.splice(indexToRemove, 1);
            FlashService.clearFlashMessage();
        }

        function resetSelection() {
            vm.selectedFiles.length = 0;
        }

        function switchEditMode(cobaResourceOrMultiLevelBundle) {
            FlashService.clearFlashMessage();
            if (vm.in_edit_focus == cobaResourceOrMultiLevelBundle.id) {
                vm.in_edit_focus = -1;
                vm.copy_in_edit_resource_or_bundle = null;
            } else {
                vm.in_edit_focus = cobaResourceOrMultiLevelBundle.id;
                vm.copy_in_edit_resource_or_bundle = angular.copy(cobaResourceOrMultiLevelBundle);
                // if it is a bundle
                if (vm.copy_in_edit_resource_or_bundle.resources) {
                    var existing_resource_ids = [];
                    angular.forEach(vm.copy_in_edit_resource_or_bundle.resources, function (resource) {
                        existing_resource_ids.push(resource.resource_id);
                    });
                    vm.copy_in_edit_resource_or_bundle.available_resources_for_bundle = [];
                    angular.forEach(vm.cobaResources, function (resource) {
                        if (existing_resource_ids.indexOf(resource.id) == -1) {
                            var available_resource = {
                                "resource_version_id": null,
                                "resource_name": resource.resource_name,
                                "resource_id": resource.id
                            };
                            vm.copy_in_edit_resource_or_bundle.available_resources_for_bundle.push(available_resource)
                        }
                    });
                    //get the resource version list for each resource in the bundle
                    angular.forEach(vm.copy_in_edit_resource_or_bundle.resources, function (resource) {
                        CobaResourceService.getCobaResourcesById(resource.resource_id)
                            .then(function (data, status, headers, config) {
                                var versionIds = [];
                                for (var j = 0; j < data.coba_resource_versions.length; j++) {
                                    versionIds.push(data.coba_resource_versions[j].id);
                                }
                                resource.versionIdsOfResource = versionIds;
                                resource.resource_name = data.resource_name;
                            })
                            .catch(function (e) {
                            });
                    });
                }
                UiEffectService.focus('cobaresource-' + vm.in_edit_focus.toString());
            }
        }

        function selectExistingResourceToInEditBundle(existing_resource) {
            CobaResourceService.getCobaResourcesById(existing_resource.resource_id)
                .then(function (data, status, headers, config) {
                    var versionIds = [];
                    for (var j = 0; j < data.coba_resource_versions.length; j++) {
                        versionIds.push(data.coba_resource_versions[j].id);
                    }
                    var resource_data = {
                        'resource_version_id': null,
                        'resource_id': data.id,
                        'resource_name': data.resource_name,
                        'versionIdsOfResource': versionIds
                    };
                    vm.copy_in_edit_resource_or_bundle.resources.push(resource_data);
                    var index_of_existing_resource = vm.copy_in_edit_resource_or_bundle.available_resources_for_bundle.indexOf(existing_resource);
                    vm.copy_in_edit_resource_or_bundle.available_resources_for_bundle.splice(index_of_existing_resource, 1);
                    vm.copy_in_edit_resource_or_bundle.used=null;
                })
        }

        function synchronizeMultiLevelBundle(multiLevelBundles) {
            MultiLevelBundleService.getMultiLevelBundlesByProject(vm.projectId)
                .then(function (data, status, headers, config) {
                    multiLevelBundles.length = data.length;
                    for (var i=0; i<data.length; i++) {
                        multiLevelBundles[i] = data[i];
                    }
                }).catch(function (e) {
                });
        }

        function synchronizeCoBaResources(coba_resources) {
            CobaResourceService.getCobaResourcesByProject(vm.projectId)
                .then(function (data, status, headers, config) {
                    coba_resources.length = data.length;
                    for (var i=0; i<data.length; i++) {
                        coba_resources[i] = data[i];
                    }
                }).catch(function (e) {
                });
        }

        function toggleSelection(cobaresource_or_bundle_id) {
            var idx = vm.selectedFiles.indexOf(cobaresource_or_bundle_id);
            // is currently selected
            if (idx > -1) {
                vm.selectedFiles.splice(idx, 1);
            }
            // is newly selected
            else {
                vm.selectedFiles.push(cobaresource_or_bundle_id);
            }
        }

        function undoEditCobaResource(cobaresource) {
            cobaresource.resource_name=vm.copy_in_edit_resource_or_bundle.resource_name;
            vm.selectedUpdateCobaFiles = null;
            vm.copy_in_edit_resource_or_bundle = null;
        }

        function undoEditMultiLevelBundle(bundle) {
            bundle.name = vm.copy_in_edit_resource_or_bundle.name;
            if (bundle.id == "new_bundle") {
                vm.multiLevelBundles.splice(0,1);
            }
            vm.copy_in_edit_resource_or_bundle = null;
        }

        function updateNewVersionOfCobaResource(newFile, resource) {
            var file = newFile;
            //document.querySelector('#newFileInput').click();
            CobaResourceService.updateCobaResourceFile(resource.resource_name, resource.id, vm.projectId, file)
                .then(function (data, status, headers, config) {
                    vm.in_edit_focus = -1;
                    vm.selectedUpdateCobaFiles = null;
                })
                .catch(function (e) {
                });
        }
    }
})();
(function() {

    'use strict';

    angular
        .module('myApp.reset_password')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = [
        '$location',
        '$log',
        '$http',
        '$q',
        '$stateParams',
        'AuthenticationService',
        'AccountProfileService',
        'FlashService',
        'UserPersistenceService',
        '$rootScope',
    ];

    // controller definition
    function ResetPasswordController(
        $location,
        $log,
        $http,
        $q,
        $stateParams,
        AuthenticationService,
        AccountProfileService,
        FlashService,
        UserPersistenceService,
        $rootScope
    ){

        var vm = this;

        vm.uidb64 = "";
        vm.token = "";
        vm.user_email = "";
        vm.new_password = "";
        vm.new_password_confirmed = "";
        vm.password_reset_error = false;
        vm.password_reset_error_message = "";

        vm.on_submit_clicked = on_submit_clicked;
        vm.validate_password_form_data = validate_password_form_data;
        vm.send_password_reset_request = send_password_reset_request;

        /* init - fetch form values for uidb64 and token from url */
        activate();
        function activate() {
            vm.uidb64 = $stateParams.uidb64;
            vm.token = $stateParams.token;
            vm.user_email = $stateParams.user_email;
        }

        /* User presses submit button in form */
        function on_submit_clicked() {

            // frontend validation failed?
            if(vm.validate_password_form_data() === false){
                return null;
            }

            // send_password_reset_request
            vm.send_password_reset_request();
        }

        /* validation in frontend

        Note:
            Catch all wrong input for uidb64 and token so we do not have a
            URLResolver failure on the backend side causing a 404. The regex
            pattern are taken straight from djangos auth/urls.py
        */
        function validate_password_form_data() {

            // uidb64 matches regex?
            var regex_pattern = '[0-9A-Za-z_\-]+';
            var regex_object = new RegExp(regex_pattern);
            if(!vm.uidb64.match(regex_object)) {
                $rootScope.critical_errors = "uidb64 " +vm.uidb64 +" did not match regex";
                vm.password_reset_error = true;
                return false;
            }

            // token matches regex?
            regex_pattern = '[0-9A-Za-z]{1,13}-[0-9A-Za-z]{1,20}';
            regex_object = new RegExp(regex_pattern);
            if(!vm.token.match(regex_object)) {
                $rootScope.critical_errors = "token " +vm.token +" did not match regex";
                vm.password_reset_error = true;
                return false;
            }

            // password mismatch?
            if(vm.new_password != vm.new_password_confirmed){
                $rootScope.critical_errors = "Passwords don't match. Try again?.";
                vm.password_reset_error = true;
                return false;
            }

            // password strength?
            if(vm.new_password.length < 8){
                $rootScope.critical_errors = "Password must contain minimum of 8 characters";
                vm.password_reset_error = true;
                return false;
            }

            return true;
        }

        /* send password reset request */
        function send_password_reset_request() {

            var on_success = function(response) {

                // loging callback for AuthenticationService
                function login_callback(response) {
                    if (response.success) {
                        var user_data = response.data;
                        AuthenticationService.SetCredentials(vm.user_email, vm.new_password);
                        $q.all([AccountProfileService.getUserAccountProfile(user_data.user_id), AccountProfileService.getUserAvatar(user_data.user_id)]).then(function (multi_response) {
                        var profile_response = multi_response[0];
                        var display_name = profile_response.first_name + ' ' + profile_response.last_name;
                        var avatar_response = multi_response[1];
                        UserPersistenceService.setUserPersistence(vm.email, user_data.user_id, user_data.user_type, display_name, avatar_response, null);
                        $rootScope.user_persist_data = UserPersistenceService.getUserPersistence();
                        $location.path('/projects');
                    })
                    }
                    else {
                        FlashService.Error(response.message);
                    }
                }

                // try to authenticate
                AuthenticationService.Login(vm.user_email, vm.new_password, login_callback);
            };

            var on_error = function(response) {
                    $rootScope.critical_errors = response.data.exception_message;
                    vm.password_reset_error = true;
            };

            var url = "/frontend/password_reset_confirm/" +vm.uidb64 +"/" +vm.token +"/";

            var data = {
                // key names match Fields in Djangos SetPasswordForm
                "new_password1" : AuthenticationService.GetPasswordHash(vm.new_password),
                "new_password2" : AuthenticationService.GetPasswordHash(vm.new_password_confirmed)
            };

            var headers = {
                "Content-Type": "application/json"
            };

            $http({
                    url: url,
                    method: "POST",
                    data: JSON.stringify(data),
                    headers: headers,
                })
                .then(on_success, on_error);
        }

    }

})();
(function () {
    'use strict';

    angular.module('myApp')
        .config(exceptionConfig);

    exceptionConfig.$inject = ['$provide'];

    function exceptionConfig($provide) {
        $provide.decorator('$exceptionHandler', extendExceptionHandler);
    }

    extendExceptionHandler.$inject = ['$delegate', '$injector'];
    function extendExceptionHandler($delegate, $injector) {
        return function (exception, cause) {
            $delegate(exception, cause);
            var $rootScope = $injector.get('$rootScope');
            $rootScope.errors = $rootScope.errors || [];
            $rootScope.errors.push(exception.message);
        };
    }

})();
(function () {

    'use strict';

    angular
        .module('myApp.reset_password', ['ui.router'])
        .config(config);

    config.$inject = [
        '$stateProvider'
    ];

    function config($stateProvider) {
        $stateProvider
            .state('password_email_input', {
                url: '/password_email_input',
                templateUrl: 'reset_password/password_email_input.html',
                controller: 'ResetPasswordEmailController',
                controllerAs: 'vm'
            })
            .state('password_input', {
                url: '/password_input?uidb64&token&user_email',
                templateUrl: 'reset_password/password_input.html',
                controller: 'ResetPasswordController',
                controllerAs: 'vm'
            })
    }

})();
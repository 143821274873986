(function () {
    'use strict';
    angular
        .module('myApp.projectdetails')
        .directive('fileModel', uploadfileModel);
    uploadfileModel.$inject = ['$parse']
    function uploadfileModel($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                element.bind('change', function () {
                    scope.$apply(function () {
                        // since FileList is readonly can't be splice
                        var writeableFileList = [];
                        for (var i = 0; i < element[0].files.length; i++) {
                            writeableFileList.push(element[0].files[i])
                        }
                        modelSetter(scope, writeableFileList);
                    });
                });
            }
        };
    }
})();

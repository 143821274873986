(function () {
    'use strict';

    angular
        .module('myApp')
        .factory('MjAjsService', MjAjsService);

    MjAjsService.$inject = ['$http', '$q', 'baseMjAjsUrl'];
    function MjAjsService($http, $q, baseMjAjsUrl) {
        var service = {};
        service.addMjAjToProject = addMjAjToProject;
        service.deleteMjAj = deleteMjAj;
        service.editMjAj = editMjAj;
        service.getMjAjsByProject = getMjAjsByProject;

        return service;

        function exceptionHandle(e) {
            return $q.reject(e);
        }

        function addMjAjToProject(project, mj, aj) {
            var new_mjaj = JSON.stringify({
                'project': project,
                'mj': mj,
                'aj': aj
            });
            return $http.post(baseMjAjsUrl, new_mjaj)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function deleteMjAj(mjaj_id) {
            return $http.delete(baseMjAjsUrl + mjaj_id + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function editMjAj(mjaj, new_mj, new_aj) {
            var changed_mjaj = JSON.stringify({
                'project': mjaj.project,
                'mj': new_mj,
                'aj': new_aj
            });
            return $http.put(baseMjAjsUrl + mjaj.id + '/', changed_mjaj)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function getMjAjsByProject(projectId) {
            return $http.get(baseMjAjsUrl + '?project_id=' + projectId)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
    }
})();
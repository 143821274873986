(function () {
    'use strict';
    angular.module('myApp.home', ['ui.router'])

        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('home', {
                    url: '/home',
                    templateUrl: 'home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                })
        }])
})();
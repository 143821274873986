(function() {

    'use strict';

    angular
        .module('myApp.reset_password')
        .controller('ResetPasswordEmailController', ResetPasswordEmailController);

    ResetPasswordEmailController.$inject = [
        '$http',
    ];

    // controller definition
    function ResetPasswordEmailController($http) {

        var vm = this;

        vm.password_email_sent = false;
        vm.forgotten_password_email_address = "";

        vm.on_forgotten_password_email_submit_clicked = on_forgotten_password_email_submit_clicked;
        vm.validate_forgotten_password_email_address = validate_forgotten_password_email_address;
        vm.send_forgotten_password_email_request = send_forgotten_password_email_request;

        /* init hook */
        activate();
        function activate() {}

        /* Handle click on Submit button in email popup */
        function on_forgotten_password_email_submit_clicked() {

            // validation failed? Most validation happens in frontend though
            if(vm.validate_forgotten_password_email_address() === false){
                return null;
            }

            // send email
            vm.send_forgotten_password_email_request();
        }

        /* Validate email value. As all validation currently happens in
        frontend, this is just a hook by now. */
        function validate_forgotten_password_email_address() {
            return true;
        }

        /* send forgotten password email */
        function send_forgotten_password_email_request() {

            var on_success = function(response) {
                vm.password_email_sent = true;
            };

            var on_error = function(response) {
                alert("Error sending email");
            };

            var data = {
                "email" : vm.forgotten_password_email_address
            };

            $http({
                url: "/frontend/password_reset_email/",
                method: "POST",
                data: JSON.stringify(data),
                headers: {"Content-Type": "application/json"},
            }).then(on_success, on_error);

        }

    }

})();

(function () {
    'use strict';

    angular.module('myApp')
        .factory('CobaResourceService', CobaResourceService);
    CobaResourceService.$inject = ['$http', '$q', 'baseResourceUrl', 'baseResourceVersionUrl'];

    function CobaResourceService($http, $q, baseResourceUrl, baseResourceVersionUrl) {
        var service = {};
        service.deleteCobaFileFromServer = deleteCobaFileFromServer;
        service.getDownloadLinkOfCobaFile = getDownloadLinkOfCobaFile;
        service.getCobaResourcesById = getCobaResourcesById;
        service.getCoBaResourceVersionStatusById = getCoBaResourceVersionStatusById;
        service.getCobaResourcesByProject = getCobaResourcesByProject;
        service.updateCobaResourceInformation = updateCobaResourceInformation;
        service.updateCobaResourceFile = updateCobaResourceFile;
        service.uploadCobaToServer = uploadCobaFileToServer;

        return service;

        function getDownloadLinkOfCobaFile(resource_version_id) {
            return baseResourceVersionUrl + resource_version_id + '/file_download';
        }
        
        function exceptionHandle(e) {
            return $q.reject(e);
        }

        function getCobaResourcesById(resourceId) {
            return $http.get(baseResourceUrl + resourceId + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function getCoBaResourceVersionStatusById(resource_version_Id) {
            var statusUrl = baseResourceVersionUrl + resource_version_Id + '/preparing_status/';
            return $http.get(statusUrl, {ignoreLoadingBar: true})
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function getCobaResourcesByProject(projectId) {
            return $http.get(baseResourceUrl + '?project_id=' + projectId)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function updateCobaResourceInformation(new_resource_name, resource_id, project_id) {
            var updateUrl = baseResourceUrl + resource_id + '/';
            var new_information = {
                'resource_name': new_resource_name,
                'project': project_id
            };
            var json_data = JSON.stringify(new_information);
            return $http.put(updateUrl, json_data)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function updateCobaResourceFile(new_resource_name, resource_id, project_id, file) {
            var updateUrl = baseResourceUrl + resource_id + '/';
            var fd = new FormData();
            fd.append('file', file);
            fd.append('resource_name', new_resource_name);
            fd.append('project', project_id);
            return $http.put(updateUrl, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function uploadCobaFileToServer(name, file, projectId) {
            var fd = new FormData();
            fd.append('file', file);
            fd.append('resource_name', name);
            fd.append('project', projectId);
            return $http.post(baseResourceUrl, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function deleteCobaFileFromServer(resource_id) {
            var deleteUrl = baseResourceUrl + resource_id + '/';
            // use get method since delete is not allowd in cws
            return $http.delete(deleteUrl)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
    }
})();
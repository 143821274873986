(function () {
    'use strict';

    angular.module('myApp.projectdetails', ['ui.router'])

        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('projectdetails', {
                    abstract: true,
                    url: '/projectdetails/:projectname/:projectid',
                    templateUrl: 'projectdetails/projectdetails.html',
                    controller: 'ProjectsDetailsController',
                    controllerAs: 'vm',
                    resolve: {
                        project_info: ['$stateParams', 'ProjectDataService',
                            function ($stateParams, ProjectDataService) {
                                return ProjectDataService.getProjectsDetailsInfo($stateParams.projectid);
                            }],
                        all_resources: ['$stateParams', 'CobaResourceService',
                            function ($stateParams, CobaResourceService) {
                                return CobaResourceService.getCobaResourcesByProject($stateParams.projectid);
                            }],
                        all_multi_level_bundles: ['$stateParams', 'MultiLevelBundleService',
                            function ($stateParams, MultiLevelBundleService) {
                                return MultiLevelBundleService.getMultiLevelBundlesByProject($stateParams.projectid);
                            }]
                    }
                })
                .state('projectdetails.all_resources', {
                    url: '',
                    templateUrl: 'projectdetails/all_resources/all_resources_of_project.html',
                    controller: 'ProjectsDetailsController',
                    controllerAs: 'vm'
                })
                .state('projectdetails.members', {
                    url: '/members',
                    templateUrl: 'projectdetails/members/members_of_project.html',
                    controller: 'ProjectMembersController',
                    controllerAs: 'vm',
                    resolve: {
                        members_list: ['$stateParams', 'ProjectDataService',
                            function ($stateParams, ProjectDataService) {
                                return ProjectDataService.getProjectMembers($stateParams.projectid);
                            }]
                    }
                })
                .state('projectdetails.saved_states', {
                    url: '/savedstates?project_id&name&market&mjaj&option_codes&categories&page_size&page',
                    reloadOnSearch: false,
                    templateUrl: 'projectdetails/saved_states/savedstates_of_project.html',
                    controller: 'ProjectSavedStatesController',
                    controllerAs: 'vm',
                    resolve: {
                        all_saved_states: ['$stateParams', 'SavedStatesService',
                            function ($stateParams, SavedStatesService) {
                                return SavedStatesService.getSavedStatesByProject(
                                    $stateParams.projectid, 15, {}, null);
                            }],
                        all_markets: ['$stateParams', 'MarketsService',
                            function ($stateParams, MarketsService) {
                                return MarketsService.getMarketsByProject($stateParams.projectid);
                            }],
                        all_mjajs: ['$stateParams', 'MjAjsService',
                            function ($stateParams, MjAjsService) {
                                return MjAjsService.getMjAjsByProject($stateParams.projectid);
                            }],
                        all_categories: ['$stateParams', 'SavedStatesCategoryService',
                            function ($stateParams, SavedStatesCategoryService) {
                                return SavedStatesCategoryService.getCategoriesByProject(
                                    $stateParams.projectid);
                            }]
                    }
                })
                .state('projectdetails.savedstates_categories', {
                    url: '/savedstates_categories',
                    templateUrl: 'projectdetails/categories/categories_of_project.html',
                    controller: 'StatesCategoriesController',
                    controllerAs: 'vm',
                    resolve: {
                        all_categories: ['$stateParams', 'SavedStatesCategoryService',
                            function ($stateParams, SavedStatesCategoryService) {
                                return SavedStatesCategoryService.getCategoriesByProject(
                                    $stateParams.projectid);
                            }]
                    }
                })
                .state('projectdetails.markets', {
                    url: '/markets',
                    templateUrl: 'projectdetails/markets/markets_of_project.html',
                    controller: 'ProjectMarketsController',
                    controllerAs: 'vm',
                    resolve: {
                        all_markets: ['$stateParams', 'MarketsService',
                            function ($stateParams, MarketsService) {
                                return MarketsService.getMarketsByProject($stateParams.projectid);
                            }]
                    }
                })
                .state('projectdetails.mjajs', {
                    url: '/mjajs',
                    templateUrl: 'projectdetails/mjajs/mjajs_of_project.html',
                    controller: 'ProjectMjAjsController',
                    controllerAs: 'vm',
                    resolve: {
                        all_mjajs: ['$stateParams', 'MjAjsService',
                            function ($stateParams, MjAjsService) {
                                return MjAjsService.getMjAjsByProject($stateParams.projectid);
                            }]
                    }
                })
        }])
})();
(function () {
    'use strict';

    angular.module('myApp.projectdetails')
        .controller('ProjectMarketsController', ProjectMarketsController);

    ProjectMarketsController.$inject = [
        '$q',
        '$rootScope',
        '$stateParams',
        'MarketsService',
        'all_markets',
        'project_info'
    ];
    function ProjectMarketsController(
        $q,
        $rootScope,
        $stateParams,
        MarketsService,
        all_markets,
        project_info) {

        var vm = this;
        vm.copy_in_edit_market = null;
        vm.error_message = '';
        vm.markets = null;
        vm.in_edit_focus = null;
        vm.selected_markets = [];
        vm.show_delete_dialog = false;
        //vm.sortReverse = false;
        //vm.sortType = 'name'

        vm.addNewEmptyMarket = addNewEmptyMarket;
        vm.deleteMarket = deleteMarket;
        vm.modifyMarket = modifyMarket;
        vm.resetSelection = resetSelection;
        vm.switchEditMode = switchEditMode;
        vm.toggleSelection = toggleSelection;
        vm.undoEdit = undoEdit;

        activate();

        function activate() {
            vm.markets = all_markets;
            vm.projectId = $stateParams.projectid;
            vm.permissions = project_info.permissions;
        }

        function addNewEmptyMarket() {
            vm.error_message = '';
            if (vm.in_edit_focus !== 'new_market') {
                var new_market = {id: 'new_market'};
                vm.markets.splice(0, 0, new_market);
                vm.in_edit_focus = 'new_market';
            } else {
                $rootScope.critical_errors = 'There is already a new market!';
            }
        }

        function deleteMarket() {
            vm.error_message = '';
            var delete_promises = [];
            angular.forEach(vm.selected_markets, function (market_id) {
                delete_promises.push(MarketsService.deleteMarket(market_id));
            });
            $q.all(delete_promises)
                .then(function () {
                    synchronizeMarkets();
                    vm.selected_markets.length = 0;
                }).catch(function (data, status, headers, config) {
                if (data.status == 500) {
                    $rootScope.critical_errors = 'Some markets is still used by other saveStates!';
                    synchronizeMarkets();
                }
            })
        }

        function modifyMarket(market) {
            vm.error_message = '';
            var errors = [];
            angular.forEach(['name', 'code'], function (att) {
                if (!market[att]) {
                    errors.push(att);
                }
            });
            if (errors.length>0) {
                $rootScope.critical_errors = 'Error: ' + errors.join(',') + ' should Not be empty!';
                return
            }
            if (market.id == 'new_market') {
                MarketsService.addMarketToProject(vm.projectId, market.code, market.name)
                    .then(function (data) {
                        synchronizeMarkets();
                        vm.in_edit_focus = null;
                        vm.error_message = '';
                    }).catch(function (data) {
                    if (data.status == 400) {
                        $rootScope.critical_errors = 'Same code exists already in project!'
                    }
                })
            } else {
                MarketsService.editMarket(market, market.code, market.name)
                    .then(function (data) {
                        market.id = data.id;
                        market.code = data.code;
                        market.name = data.name;
                        vm.in_edit_focus = null;
                    }).catch(function (data, status, headers, config) {
                })
            }
        }

        function resetSelection() {
            vm.selected_markets.length = 0;
            vm.error_message = '';
        }

        function switchEditMode(market) {
            if (vm.in_edit_focus === market.id) {
                vm.in_edit_focus = null;
            } else {
                vm.in_edit_focus = market.id;
                vm.copy_in_edit_market = angular.copy(market);
            }
        }

        function synchronizeMarkets() {
            MarketsService.getMarketsByProject(vm.projectId)
                .then(function (data) {
                    vm.markets = data;
                })
        }

        function toggleSelection(market_id) {
            var idx = vm.selected_markets.indexOf(market_id);
            // is currently selected
            if (idx > -1) {
                vm.selected_markets.splice(idx, 1);
            }
            // is newly selected
            else {
                vm.selected_markets.push(market_id);
            }
        }

        function undoEdit(market) {
            vm.error_message = '';
            if (market.id == 'new_market') {
                vm.markets.splice(0, 1);
            } else {
                market.code = vm.copy_in_edit_market.code;
                market.name = vm.copy_in_edit_market.name;
                vm.copy_in_edit_market = null;
            }
        }
    }
})();
(function () {
    'use strict';

    angular.module('myApp.projectdetails')
        .controller('StatesCategoriesController', StatesCategoriesController);
    
    StatesCategoriesController.$inject = [
        '$q',
        '$rootScope',
        '$stateParams',
        'SavedStatesCategoryService',
        'all_categories',
        'project_info'
    ];
    function StatesCategoriesController(
        $q,
        $rootScope,
        $stateParams,
        SavedStatesCategoryService,
        all_categories,
        project_info) {

        var vm = this;
        vm.copy_in_edit_category = null;
        vm.error_message = '';
        vm.categories = null;
        vm.in_edit_focus = null;
        vm.selected_categories = [];
        vm.show_delete_dialog = false;
        //vm.sortReverse = false;
        //vm.sortType = 'name'

        vm.addNewEmptyCategory = addNewEmptyCategory;
        vm.deleteCategory = deleteCategory;
        vm.modifyCategory = modifyCategory;
        vm.resetSelection = resetSelection;
        vm.switchEditMode = switchEditMode;
        vm.toggleSelection = toggleSelection;
        vm.undoEdit = undoEdit;

        activate();

        function activate() {
            vm.categories = all_categories;
            vm.projectId = $stateParams.projectid;
            vm.permissions = project_info.permissions;
        }

        function addNewEmptyCategory() {
            vm.error_message = '';
            if (vm.in_edit_focus !== 'new_category') {
                var new_category = {id: 'new_category'};
                vm.categories.splice(0, 0, new_category);
                vm.in_edit_focus = 'new_category';
            } else {
                vm.error_message = 'There is already a new category!';
            }
        }

        function deleteCategory() {
            vm.error_message = '';
            var delete_promises = [];
            angular.forEach(vm.selected_categories, function (cate_id) {
                delete_promises.push(SavedStatesCategoryService.deleteCategory(cate_id));
            });
            $q.all(delete_promises)
                .then(function () {
                    synchronizeCategories();
                    vm.selected_categories.length = 0;
                }).catch(function (data, status, headers, config) {
                if (data.status == 500) {
                    vm.error_message = 'This category is still used by other saveStates!';
                }
            })
        }

        function modifyCategory(category) {
            vm.error_message = '';
            if (!category.name) {
                vm.error_message = 'Error: name should Not be empty!';
                return
            }
            if (category.id == 'new_category') {
                SavedStatesCategoryService.addCategoryToProject(vm.projectId, category.name)
                    .then(function (data) {
                        synchronizeCategories();
                        vm.in_edit_focus = null;
                        vm.error_message = '';
                    }).catch(function (data) {
                    if (data.status == 400) {
                        $rootScope.critical_errors = 'Category with same name exists already in project!'
                    }
                })
            } else {
                SavedStatesCategoryService.editCategory(category, category.name)
                    .then(function (data) {
                        category.id = data.id;
                        category.name = data.name;
                        vm.in_edit_focus = null;
                    }).catch(function (data, status, headers, config) {
                })
            }
        }
        
        function resetSelection() {
            vm.selected_categories.length = 0;
            vm.error_message = '';
        }
        
        function switchEditMode(category) {
            if (vm.in_edit_focus === category.id) {
                vm.in_edit_focus = null;
            } else {
                vm.in_edit_focus = category.id;
                vm.copy_in_edit_category = angular.copy(category);
            }
        }

        function synchronizeCategories() {
            SavedStatesCategoryService.getCategoriesByProject(vm.projectId)
                .then(function (data) {
                    vm.categories = data;
                })
        }
        
        function toggleSelection(cate_id) {
            var idx = vm.selected_categories.indexOf(cate_id);
            // is currently selected
            if (idx > -1) {
                vm.selected_categories.splice(idx, 1);
            }
            // is newly selected
            else {
                vm.selected_categories.push(cate_id);
            }
        }
        
        function undoEdit(category) {
            vm.error_message = '';
            if (category.id == 'new_category') {
                vm.categories.splice(0, 1);
            } else {
                category.name = vm.copy_in_edit_category.name;
                vm.copy_in_edit_category = null;
            }
        }
    }
})();
(function () {
    'use strict';
    angular.module('myApp.projects')
        .controller('ProjectsCtrl', projectsController)

    projectsController.$inject = [
        '$location', '$q', '$rootScope', 'all_accessible_projects','ProjectDataService', 'UiEffectService']

    function projectsController(
        $location, $q, $rootScope, all_accessible_projects, ProjectDataService, UiEffectService) {

        var vm = this;

        vm.allExpanded = false;
        vm.copyOfInEditProject = null;
        vm.newProjectName = '';
        vm.in_edit_focus = -1;
        vm.searchProject = '';
        vm.selectedProjectsToDelete = [];
        vm.sortReverse = false;
        vm.sortType = 'name';
        vm.showDeleteDialog = false;

        vm.addProject = addProject;
        vm.editProject = editProject;
        vm.copyProject = copyProject;
        vm.goToDetails = goToDetails;
        vm.removeProject = removeProject;
        vm.toggleSelection = toggleSelection;
        vm.resetSelection = resetSelection;
        vm.switchAddMode = switchAddMode;
        vm.switchEditMode = switchEditMode;

        activate();

        // function definitions
        function activate() {
            vm.projects = all_accessible_projects;
            $rootScope.currentNaviAction = 'projects';
        }

        function addProject() {
            vm.newProjectName = vm.newProjectName.trim();
            if (!vm.newProjectName) {
                return;
            }
            ProjectDataService.addNewProject({name: vm.newProjectName})
                .then(function (data, status, headers, config) {
                    vm.projects.push({
                        name: data.name,
                        id: data.id,
                        permissions: data.permissions
                    });
                    vm.newProjectName = '';
                    vm.in_edit_focus = -1;
                }).catch(function (data, status, headers, config) {
            });
        }

        function editProject(project) {
            if (project.custom_name.trim() &&
                ((project.custom_name != project.name) || (project.custom_code != project.code)) ) {

                project.name = project.custom_name.trim();
                project.code = project.custom_code.trim();
                ProjectDataService.updateProject(project)
                    .then(function (data, status, headers, config) {
                        vm.in_edit_focus = -1;
                    }).catch(function (data, status, headers, config) {
                    project.custom_name = vm.copyOfInEditProject.name;
                    project.name = vm.copyOfInEditProject.name;
                    project.custom_code = vm.copyOfInEditProject.code;
                    project.code = vm.copyOfInEditProject.code;
                });
            } else {
                project.custom_name = project.name;
                project.custom_code = project.code;
            }

        }

        function copyProject(project) {
            vm.copyOfInEditProject = angular.copy(project);
        }

        function goToDetails(project) {
            $location.path('/projectdetails/' + project.name + '/' + project.id);
        }

        function removeProject() {
            var delete_promises = [];
            angular.forEach(vm.selectedProjectsToDelete, function (projectId) {
                delete_promises.push(ProjectDataService.deleteProject(projectId));
            });
            $q.all(delete_promises)
                .then(function () {
                    ProjectDataService.getProjectsInfo()
                        .then(function (data, status, headers, config) {
                            vm.projects = data;
                            vm.selectedProjectsToDelete.length = 0;
                        })
                        .catch(function (data, status, headers, config) {
                        })
                });
        }

        function resetSelection() {
            vm.selectedProjectsToDelete.length = 0;
        }

        function switchAddMode() {
            if ((vm.in_edit_focus!='newproject')) {
                vm.in_edit_focus ='newproject';
                UiEffectService.focus('addnewproject');
            }else {
                vm.in_edit_focus = -1;
            }
        }

        function switchEditMode(project) {
            if (vm.in_edit_focus == project.id) {
                vm.in_edit_focus = -1;
            } else {
                vm.in_edit_focus = project.id;
                vm.copyProject(project);
                UiEffectService.focus(vm.in_edit_focus.toString());
            }
        }

        function toggleSelection(project_id) {
            var idx = vm.selectedProjectsToDelete.indexOf(project_id);
            // is currently selected
            if (idx > -1) {
                vm.selectedProjectsToDelete.splice(idx, 1);
            }
            // is newly selected
            else {
                vm.selectedProjectsToDelete.push(project_id);
            }
        }
    }
})();

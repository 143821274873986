(function () {
    'use strict';

    // Declare app level module which depends on views, and components
    angular.module('myApp', [
        'ngRoute',
        'ngSanitize',
        'ui.router',
        'ui.select',
        'myApp.home',
        'myApp.account',
        'myApp.logout',
        'myApp.projects',
        'myApp.projectdetails',
        'myApp.resourcedetails',
        'myApp.reset_password',
        'myApp.userAdmin',
        'ngCookies',
        'ngImgCrop',
        'dndLists',
        'angular-loading-bar'
    ])
        .config(appConfig)
        .controller('navCtrl', navCtrl)
        .run(run)            
    
    appConfig.$inject = ['$locationProvider', '$urlRouterProvider', '$httpProvider', '$interpolateProvider', 'cfpLoadingBarProvider'];
    function appConfig($locationProvider, $urlRouterProvider, $httpProvider, $interpolateProvider, cfpLoadingBarProvider) {
        $locationProvider.hashPrefix('!');

        $urlRouterProvider.otherwise('/home');
        $httpProvider.defaults.xsrfCookieName = 'csrftoken';
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
        $httpProvider.interceptors.push("HttpErrorInterceptor");

        // Specify JS tokens for databinding. Default of {{ }} conflicts with
        // Django templates. If redefined here, Django and AngularJS vars
        // can coexists in the same template.
        $interpolateProvider.startSymbol('{[{');
        $interpolateProvider.endSymbol('}]}');

        cfpLoadingBarProvider.includeSpinner = false;

    }

    navCtrl.$inject = ['$scope'];
    function navCtrl($scope) {
        $scope.currentPage = "projects";
    }

    run.$inject = ['$rootScope', '$cookies', '$http', 'UserPersistenceService'];
    function run($rootScope, $cookies, $http, UserPersistenceService) {
        // keep user logged in after page refresh
        $rootScope.globals = $cookies.getObject('globals') || {};
        $rootScope.user_persist_data = UserPersistenceService.getUserPersistence();
        $rootScope.current_year = new Date().getFullYear();
        if ($rootScope.user_persist_data) {
            $rootScope.loggedIn = true;
        }
        if ($rootScope.globals.currentUser) {
            $http.defaults.headers.common['Authorization'] = 'Basic ' + $rootScope.globals.currentUser.authdata; // jshint ignore:line
        }
    }
})();
(function () {
    'use strict';

    angular.module('myApp.userAdmin', ['ui.router'])

        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('useradmin', {
                    url: '/useradmin',
                    templateUrl: 'useradmin/useradmin.html',
                    controller: 'userAdminCtrl',
                    controllerAs: 'vm',
                    resolve: {
                        all_users: ['AccountProfileService',
                            function (AccountProfileService) {
                                return AccountProfileService.getAllUsersProfiles();
                            }]
                    }
                })
        }]);
})();

(function () {
    'use strict';
    angular
        .module('myApp')
        .factory("HttpErrorInterceptor", HttpErrorInterceptor);
    HttpErrorInterceptor.inject = ["$q", "$rootScope", "$location", "UserPersistenceService"];
    function HttpErrorInterceptor($q, $rootScope, $location, UserPersistenceService) {
        return {
            'responseError': responseError,
            'requestError': requestError
        };

        function responseError(rejection) {
            // do check on unauthorized or forbidden error
            // status == -1 happens, when nginx actively rejects request by uploading big file, when user is already logout
            //CLIENT ERRORS
            if (rejection.status === 404) {
                $rootScope.critical_errors = "The requested resource could not be found but may be available in the future.";
            }
            return $q.reject(rejection);
        }

        function requestError(rejection) {
            // do check on unauthorized or forbidden error
            if (rejection.status === 401 || rejection.status === 403) {
                $rootScope.critical_errors = 'You need to login to perform this action!';
                $rootScope.loggedIn = false;
                UserPersistenceService.clearUserPersistence();
                $location.path('/home');
            }
            return $q.reject(rejection);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('myApp')
        .factory('SavedStatesService', SavedStatesService);

    SavedStatesService.$inject = ['$http', '$q', 'baseSavedStatesUrl'];
    function SavedStatesService($http, $q, baseSavedStatesUrl) {
        var service = {};
        service.addSavedStateToProject = addSavedStateToProject;
        service.deleteSavedState = deleteSavedState;
        service.editSavedState = editSavedState;
        service.getSavedStatesByParamUrl = getSavedStatesByParamUrl;
        service.getSavedStatesByProject = getSavedStatesByProject;

        return service;

        function exceptionHandle(e) {
            return $q.reject(e);
        }

        function addSavedStateToProject(project_id, name, option_codes, market_id, mjaj_id, cate_ids) {
            var new_saved_state = JSON.stringify({
                'project': project_id*1,
                'option_codes': option_codes,
                'name': name,
                'market': market_id,
                'mjaj': mjaj_id
            });

            return $http.post(baseSavedStatesUrl, new_saved_state)
                .then(function (data, status, headers, config) {
                    if (cate_ids) {
                        var state_id = data.data.id;
                        var categories = JSON.stringify({'categories': cate_ids});
                        $http.post(baseSavedStatesUrl + state_id.toString() + '/set-categories/', categories)
                            .then(function (data, status, headers, config) {
                                return data.data;
                            })
                            .catch(exceptionHandle);
                    } else {
                        return data.data;
                    }
                })
                .catch(exceptionHandle);
        }

        function deleteSavedState(saved_state_id) {
            return $http.delete(baseSavedStatesUrl + saved_state_id + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function editSavedState(saved_state, cate_ids) {
            var changed_saved_state = JSON.stringify({
                'project': saved_state.project,
                'option_codes': saved_state.option_codes,
                'name': saved_state.name,
                'market': saved_state.market,
                'mjaj': saved_state.mjaj
            });
            return $http.put(baseSavedStatesUrl + saved_state.id + '/', changed_saved_state)
                .then(function (data, status, headers, config) {
                    var state_id = data.data.id;
                    var categories = JSON.stringify({'categories': cate_ids});
                    $http.post(baseSavedStatesUrl + state_id.toString() + '/set-categories/', categories)
                        .then(function (data, status, headers, config) {
                            return data.data;
                        })
                        .catch(exceptionHandle);
                })
                .catch(exceptionHandle);
        }

        function getSavedStatesByProject(projectId, page_size, filters, page_number) {
            var basic_call_url = baseSavedStatesUrl + '?project_id=' + projectId;
            var extra_params = [];
            if (filters) {
                if (filters.name) {
                    extra_params.push('name=' + filters.name);
                }
                if (filters.markets&&filters.markets.length) {
                    extra_params.push('market=' + filters.markets.join(','));
                }
                if (filters.mjajs&&filters.mjajs.length) {
                    extra_params.push('mjaj=' + filters.mjajs.join(','));
                }
                if (filters.option_codes) {
                    extra_params.push('option_codes=' + filters.option_codes.join('&option_codes='));
                }
                if (filters.categories&&filters.categories.length) {
                    extra_params.push('categories=' + filters.categories.join('&categories='));
                }
            }
            if (page_size) {
                extra_params.push('page_size=' + page_size.toString());
            }
            if (page_number) {
                extra_params.push('page=' + page_number.toString());
            }
            if (extra_params) {
                basic_call_url += '&' + extra_params.join('&');
            }
            return $http.get(basic_call_url)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function getSavedStatesByParamUrl(param_url) {
            return $http.get(baseSavedStatesUrl + '?' + param_url)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
    }
})();
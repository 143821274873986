(function () {
    'use strict';
    angular
        .module('myApp')
        .factory('UiEffectService', UiEffectService);

    UiEffectService.$inject = ['$rootScope', '$timeout'];
    function UiEffectService($rootScope, $timeout) {
        var service = {};

        service.focus = focus;

        return service;

        function focus(name) {
            $timeout(function () {
                $rootScope.$broadcast('focusOn', name);
            });
        }
    }
})();
(function () {
    'use strict';

    angular.module('myApp.account')
        .controller('accountCtrl', AccountController)
    AccountController.$inject = [
        '$rootScope',
        '$scope',
        '$location',
        'AccountProfileService',
        'UserPersistenceService',
        'AuthenticationService',
        'defaultHoverPicPath']

    function AccountController($rootScope, $scope, $location, AccountProfileService, UserPersistenceService, AuthenticationService, defaultHoverPicPath) {
        var vm = this;

        vm.errorMessage = "";
        vm.message = "";
        vm.currentView = "general";
        vm.password = "";
        vm.confirmPassword = "";
        vm.newAvatar = '';
        vm.newCroppedAvatar = '';
        vm.hoverTempAvatar = defaultHoverPicPath;

        vm.generateNewToken = generateNewToken;
        vm.resetUploadAvatar = resetUploadAvatar;
        vm.switchHoverPicture = switchHoverPicture;
        vm.updateAccount = updateAccount;
        vm.updateAvatar = updateAvatar;

        activate();
        // function definitions
        function activate() {
            try {
                vm.user_id = UserPersistenceService.getUserPersistence().user_id;
                vm.userAvatar = UserPersistenceService.getUserPersistence().avatar;
            }
            catch (err) {
                $location.path('/signin');
            }
            angular.element(document.querySelector('#upload-new-avatar')).on('change', readSelectedNewAvatar);
            $rootScope.currentNaviAction = 'account';
            AccountProfileService.getUserAccountProfile(vm.user_id)
                .then(function (response) {
                    vm.user = response;
                })
                .catch(function (response) {
                    $location.path('/signin');
                });
        };

        function generateNewToken() {
            AccountProfileService.getNewApiToken(vm.user_id)
                .then(function (response) {
                    vm.user.api_token_string = response;
                })
                .catch(function (response) {
                });
        };

        function updateAccount() {
            if (vm.confirmPassword) {
                vm.user['password'] = AuthenticationService.GetPasswordHash(vm.confirmPassword);
            }
            AccountProfileService.updateAccountProfile(vm.user_id, vm.user)
                .then(function (response) {
                    vm.message = "success update";
                    var credential_changed = vm.confirmPassword || vm.user.email !== response.email;
                    vm.user = response;
                    if (credential_changed) {
                        AuthenticationService.ClearCredentials();
                        AuthenticationService.SetCredentials(vm.user.email, vm.confirmPassword);
                    }
                    var currentPersistence = UserPersistenceService.getUserPersistence();
                    var display_name = vm.user.first_name + ' ' + vm.user.last_name;
                    UserPersistenceService.setUserPersistence(
                        vm.user.email, currentPersistence.user_id, currentPersistence.user_type, display_name, currentPersistence.avatar);
                    $rootScope.user_persist_data = UserPersistenceService.getUserPersistence();
                    vm.password = "";
                    vm.confirmPassword = "";
                })
                .catch(function (response) {
                    console.log(response);
                });
        };

        function resetUploadAvatar() {
            vm.newAvatar = '';
            vm.newCroppedAvatar = '';
        }
        function updateAvatar() {
            AccountProfileService.updateUserAvatar(vm.user_id, vm.newCroppedAvatar).then(function (response) {
                vm.userAvatar = vm.newCroppedAvatar;
                UserPersistenceService.updateUserAvatar(vm.userAvatar);
                $rootScope.user_persist_data = UserPersistenceService.getUserPersistence();
                resetUploadAvatar();
            });
        }
        function readSelectedNewAvatar(evt) {
            var file = evt.currentTarget.files[0];
            if (file && file.size > 1048576) {
                $rootScope.critical_errors = 'Selected picture is bigger than 1MB, please choose a smaller avatar-file!';
                return;
            }
            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function () {
                    vm.newAvatar = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };

        function switchHoverPicture() {
            var temppic = vm.userAvatar;
            vm.userAvatar = vm.hoverTempAvatar;
            vm.hoverTempAvatar = temppic;
        }
    }
})();

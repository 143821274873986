'use strict';

angular.module('myApp.logout', ['ui.router'])

    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('logout', {
                url: '/logout',
                controller: 'LogoutCtrl'
            })
    }])

.controller('LogoutCtrl', ['$http', '$scope', 'AuthenticationService', '$location', 'UserPersistenceService',
function($http, $scope, AuthenticationService, $location, UserPersistenceService) {
    UserPersistenceService.clearUserPersistence();
    AuthenticationService.Logout(function (response) {
    			if (response.success) {
				    $location.path('/home');
			    }
		});
}]);



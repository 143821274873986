(function () {
    'use strict';
    angular.module('myApp.projects', ['ui.router'])

        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('projects', {
                    url: '/projects',
                    templateUrl: 'projects/projects.html',
                    controller: 'ProjectsCtrl',
                    controllerAs: 'vm',
                    resolve: {
                        all_accessible_projects: ['ProjectDataService',
                            function (ProjectDataService) {
                                return ProjectDataService.getProjectsInfo();
                            }]
                    }
                })
        }])
})();

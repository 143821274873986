(function () {
    'use strict';

    angular.module('myApp')
        .factory('MultiLevelBundleService', MultiLevelBundleService);
    MultiLevelBundleService.$inject = ['$http', '$q', 'baseMultiLevelBundleUrl'];

    function MultiLevelBundleService($http, $q, baseMultiLevelBundleUrl) {
        var service = {};

        service.createMultiLevelBundle = createMultiLevelBundle;
        service.getMultiLevelBundleById = getMultiLevelBundleById;
        service.getMultiLevelBundlesByProject = getMultiLevelBundlesByProject;
        service.updateMultiLevelBundle = updateMultiLevelBundle;
        service.deleteMultiLevelBundle = deleteMultiLevelBundle;

        return service;

        function exceptionHandle(e) {
            return $q.reject(e);
        }

        function getMultiLevelBundleById(bundle_id) {
            return $http.get(baseMultiLevelBundleUrl + bundle_id + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
        function getMultiLevelBundlesByProject(project_id) {
            return $http.get(baseMultiLevelBundleUrl + '?project_id=' + project_id)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function createMultiLevelBundle(bundle_name, project_id, resources) {
            var new_information = {
                'name': bundle_name,
                'project': project_id,
                'resources': resources
            };
            var json_data = JSON.stringify(new_information);
            return $http.post(baseMultiLevelBundleUrl, json_data)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function updateMultiLevelBundle(new_bundle_name, bundle_id, project_id, resources) {
            var updateUrl = baseMultiLevelBundleUrl + bundle_id + '/';
            var new_information = {
                'name': new_bundle_name,
                'project': project_id,
                'resources': resources
            };
            var json_data = JSON.stringify(new_information);
            return $http.put(updateUrl, json_data)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function deleteMultiLevelBundle(bundle_id) {
            var deleteUrl = baseMultiLevelBundleUrl + bundle_id + '/';
            return $http.delete(deleteUrl)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
    }
})();
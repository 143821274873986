(function () {
    'use strict';

    angular.module('myApp.account', ['ui.router'])

        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('account', {
                    url: '/account',
                    templateUrl: 'account/account.html',
                    controller: 'accountCtrl',
                    controllerAs: 'vm'
                })
        }])
})();

(function () {
    'use strict';

    angular
        .module('myApp')
        .factory('AccountProfileService', AccountProfileService);

    AccountProfileService.$inject = ['$http', '$q', 'baseAccountUrl', 'baseTokenUrl', 'baseAvatarUrl'];
    function AccountProfileService($http, $q, baseAccountUrl, baseTokenUrl, baseAvatarUrl) {
        var service = {};

        service.createNewUser = createNewUser;
        service.getAllUsersProfiles = getAllUsersProfiles;
        service.getNewApiToken = getNewApiToken;
        service.getUserAccountProfile = getUserAccountProfile;
        service.getUserAvatar = getUserAvatar;
        service.updateAccountProfile = updateAccountProfile;
        service.updateUserAvatar = updateUserAvatar;

        return service;

        function createNewUser(new_user_profile) {
            var json_data = JSON.stringify(convertUserDict(new_user_profile));
            return $http.post(baseAccountUrl, json_data)
                .then(function (data, status, headers, config) {
                    return convertResponseData(data.data);
                })
                .catch(function (e) {
                    return $q.reject(e);
                });
        }

        function getAllUsersProfiles() {
            return $http.get(baseAccountUrl)
                .then(function (data, status, headers, config) {
                    var all_user = [];
                    var user_data = null;
                    for (var i = 0; i < data.data.length; i++) {
                        user_data = convertResponseData(data.data[i]);
                        if (user_data) {
                            all_user.push(user_data);
                        }
                    }
                    return all_user;
                })
                .catch(function (e) {
                    return $q.reject(e);
                });
        }

        function getNewApiToken(user_id) {
            return $http.patch(baseTokenUrl + user_id + '/')
                .then(function (data, status, headers, config) {
                    return data.data.key;
                })
                .catch(function (e) {
                    return $q.reject(e);
                });
        }

        function getUserAccountProfile(user_id) {
            return $http.get(baseAccountUrl + user_id + '/')
                .then(function (data, status, headers, config) {
                    return convertResponseData(data.data);
                })
                .catch(function (e) {
                    return $q.reject(e);
                });
        }

        function getUserAvatar(user_id) {
            return $http.get(baseAvatarUrl + user_id + '/')
                .then(function (data, status, headers, config) {
                    return data.data.image_binary;
                })
                .catch(function (e) {
                    return '';
                });
        }

        function updateAccountProfile(user_id, new_user_profile) {
            var json_data = JSON.stringify(convertUserDict(new_user_profile));
            return $http.put(baseAccountUrl + user_id + '/', json_data)
                .then(function (data, status, headers, config) {
                    return convertResponseData(data.data);
                })
                .catch(function (e) {
                    return $q.reject(e);
                });
        }

        function updateUserAvatar(user_id, new_avatar) {
            var json_data = JSON.stringify({'image_binary': new_avatar});
            var updateUrl = baseAvatarUrl + user_id + '/';
            return $http.patch(updateUrl, json_data)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(function (e) {
                    return $q.reject(e);
                });
        }

        function convertResponseData(response_data) {
            try {
                var user_dict = {
                    'first_name': response_data.first_name,
                    'last_name': response_data.last_name,
                    'email': response_data.email,
                    'id': response_data.id,
                    'company': response_data.profile.company,
                    'phone_number': response_data.profile.phone_number,
                    'inform_changes': response_data.profile.inform_changes,
                    'inform_releases': response_data.profile.inform_releases,
                    'is_support_user': response_data.profile.is_support_user,
                    'is_payment_account': response_data.profile.is_payment_account,
                    'active_at': response_data.profile.active_at,
                    'end_at': response_data.profile.end_at,
                    'api_token_string': response_data.auth_token.key
                };
                if (response_data.is_active) {
                    user_dict['is_active'] = response_data.is_active;
                }
            } catch (err) {
                var user_dict = null;
            }
            return user_dict;
        }

        function convertUserDict(user_profile_dict) {
            var dict_to_update = {
                'first_name': user_profile_dict['first_name'],
                'last_name': user_profile_dict['last_name'],
                'email': user_profile_dict['email'],
                'profile': {
                    'is_payment_account': user_profile_dict['is_payment_account'],
                    'is_support_user': user_profile_dict['is_support_user'],
                    'phone_number': user_profile_dict['phone_number'],
                    'company': user_profile_dict['company'],
                    'active_at': user_profile_dict['active_at'],
                    'end_at': user_profile_dict['end_at'],
                    'inform_changes': user_profile_dict['inform_changes'],
                    'inform_releases': user_profile_dict['inform_releases']
                }
            };
            if ('password' in user_profile_dict) {
                dict_to_update['password'] = user_profile_dict['password'];
            }
            if ('is_active' in user_profile_dict) {
                dict_to_update['is_active'] = user_profile_dict['is_active'];
            }
            return dict_to_update;
        }
    }
})();
(function () {
    'use strict';

    angular
        .module('myApp')
        .factory('MarketsService', MarketsService);

    MarketsService.$inject = ['$http', '$q', 'baseMarketsUrl'];
    function MarketsService($http, $q, baseMarketsUrl) {
        var service = {};
        service.addMarketToProject = addMarketToProject;
        service.deleteMarket = deleteMarket;
        service.editMarket = editMarket;
        service.getMarketsByProject = getMarketsByProject;

        return service;

        function exceptionHandle(e) {
            return $q.reject(e);
        }

        function addMarketToProject(project, code, name) {
            var new_market = JSON.stringify({
                'project': project,
                'code': code,
                'name': name
            });
            return $http.post(baseMarketsUrl, new_market)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function deleteMarket(market_id) {
            return $http.delete(baseMarketsUrl + market_id + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function editMarket(market, new_code, new_name) {
            var changed_market = JSON.stringify({
                'project': market.project,
                'code': new_code,
                'name': new_name
            });
            return $http.put(baseMarketsUrl + market.id + '/', changed_market)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function getMarketsByProject(projectId) {
            return $http.get(baseMarketsUrl + '?project_id=' + projectId)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
    }
})();
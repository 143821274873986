(function () {
    'use strict';

    angular.module('myApp.projectdetails')
        .controller('ProjectMembersController', ProjectMembersController);

    ProjectMembersController.$inject = [
        '$q',
        '$rootScope',
        '$stateParams',
        'ProjectDataService',
        'members_list',
        'project_info'];
    function ProjectMembersController($q,
                                      $rootScope,
                                      $stateParams,
                                      ProjectDataService,
                                      members_list,
                                      project_info) {

        var vm = this;
        vm.copy_in_edit_member = null;
        vm.error_message = '';
        vm.members = null;
        vm.in_edit_focus = null;
        vm.new_user_email = '';
        vm.new_user_type = 'Read user';
        vm.selected_members = [];
        vm.show_delete_dialog = false;
        //vm.sortReverse = false;
        //vm.sortType = 'name'

        vm.addMemberToProject = addMemberToProject;
        vm.editPermissionOfMember = editPermissionOfMember;
        vm.isMyself = isMyself;
        vm.removeMemberFromProject = removeMemberFromProject;
        vm.resetSelection = resetSelection;
        vm.searchUser = searchUser;
        vm.switchEditMode = switchEditMode;
        vm.toggleSelection = toggleSelection;
        vm.undoEdit = undoEdit;

        activate();

        function activate() {
            vm.members = [];
            vm.projectId = $stateParams.projectid;
            vm.permissions = project_info.permissions;
            for (var email in members_list) {
                var member = {};
                member.email = email;
                member.type = getUserTypeByPermission(members_list[email]);
                vm.members.push(member);
            }
        }

        function addMemberToProject() {
            searchUser();
            if (vm.error_message) {
                return
            }
            var permissions = convertTypeToPermission(vm.new_user_type);
            ProjectDataService.editProjectMembers(vm.projectId, vm.new_user_email, permissions)
                .then(function (data, status, headers, config) {
                    synchronizeMembers();
                    vm.new_user_email = null;
                    vm.new_user_type = 'Read user';
                    vm.in_edit_focus = null;
                }).catch(function (data, status, headers, config) {
                if (data.status === 404) {
                    $rootScope.critical_errors = 'User not found';
                }
            })
        }

        function convertTypeToPermission(user_type) {
            var permissions = {'read': true, 'write': false, 'maintain': false};
            if (user_type === 'Write user') {
                permissions.write = true;
            } else if (user_type === 'Maintainer') {
                permissions.write = true;
                permissions.maintain = true;
            }
            return permissions;
        }

        function editPermissionOfMember(member) {
            vm.error_message = '';
            if (member.type != vm.copy_in_edit_member.type) {
                var permissions = convertTypeToPermission(member.type);
                ProjectDataService.editProjectMembers(vm.projectId, member.email, permissions)
                    .then(function (data, status, headers, config) {
                        member.type = getUserTypeByPermission(data);
                        vm.in_edit_focus = null;
                    }).catch(function (data, status, headers, config) {
                    member.type = vm.copy_in_edit_member.type;
                    $rootScope.critical_errors = member.type + 'can Not assign to ' + member.email;
                })
            }
        }

        function getUserTypeByPermission(permissions) {
            var user_type = 'Read user';
            if (permissions.maintain) {
                user_type = 'Maintainer';
            } else if (permissions.write) {
                user_type = 'Write user';
            }
            return user_type
        }
        
        function isMyself(member) {
            return member.email === $rootScope.user_persist_data.user_email;
        }

        function removeMemberFromProject() {
            var permissions = {'read': false, 'write': false, 'maintain': false};
            vm.error_message = '';
            var remove_promises = [];
            angular.forEach(vm.selected_members, function (member_email) {
                remove_promises.push(
                    ProjectDataService.editProjectMembers(
                        vm.projectId, member_email, permissions));
            });
            $q.all(remove_promises)
                .then(function () {
                    synchronizeMembers();
                    vm.selected_members.length = 0;
                }).catch(function (data, status, headers, config) {
                $rootScope.critical_errors = 'Some users can not be removed!';
            })
        }

        function resetSelection() {
            vm.selected_members.length = 0;
            vm.error_message = '';
        }

        function searchUser() {
            vm.error_message = '';
            if (vm.new_user_email) {
                for (var i = 0; i < vm.members.length; i++) {
                    if (vm.members[i].email === vm.new_user_email.trim()) {
                        $rootScope.critical_errors = 'Email already in members';
                        break
                    }
                }
            }
        }

        function switchEditMode(member) {
            if (vm.in_edit_focus === member.email) {
                vm.in_edit_focus = null;
            } else {
                vm.in_edit_focus = member.email;
                vm.copy_in_edit_member = angular.copy(member);
            }
        }

        function synchronizeMembers() {
            ProjectDataService.getProjectMembers(vm.projectId)
                .then(function (data) {
                    vm.members = [];
                    for (var email in data) {
                        var member = {};
                        member.email = email;
                        member.type = getUserTypeByPermission(data[email]);
                        vm.members.push(member);
                    }
                })
        }

        function toggleSelection(member_email) {
            var idx = vm.selected_members.indexOf(member_email);
            // is currently selected
            if (idx > -1) {
                vm.selected_members.splice(idx, 1);
            }
            // is newly selected
            else {
                vm.selected_members.push(member_email);
            }
        }

        function undoEdit(member) {
            member.type = vm.copy_in_edit_member.type;
            vm.copy_in_edit_member = null;
        }
    }
})();

(function () {
    'use strict';

    angular.module('myApp.projectdetails')
        .controller('ProjectMjAjsController', ProjectMjAjsController);

    ProjectMjAjsController.$inject = [
        '$q',
        '$rootScope',
        '$stateParams',
        'MjAjsService',
        'all_mjajs',
        'project_info'
    ];
    function ProjectMjAjsController(
        $q,
        $rootScope,
        $stateParams,
        MjAjsService,
        all_mjajs,
        project_info) {

        var vm = this;
        vm.copy_in_edit_mjaj = null;
        vm.error_message = '';
        vm.mjajs = null;
        vm.in_edit_focus = null;
        vm.selected_mjajs = [];
        vm.show_delete_dialog = false;
        //vm.sortReverse = false;
        //vm.sortType = 'name'

        vm.addNewEmptyMjAj = addNewEmptyMjAj;
        vm.deleteMjAj = deleteMjAj;
        vm.modifyMjAj = modifyMjAj;
        vm.resetSelection = resetSelection;
        vm.switchEditMode = switchEditMode;
        vm.toggleSelection = toggleSelection;
        vm.undoEdit = undoEdit;

        activate();

        function activate() {
            vm.mjajs = all_mjajs;
            vm.projectId = $stateParams.projectid;
            vm.permissions = project_info.permissions;
        }

        function addNewEmptyMjAj() {
            vm.error_message = '';
            if (vm.in_edit_focus !== 'new_mjaj') {
                var new_mjaj = {id: 'new_mjaj'};
                vm.mjajs.splice(0, 0, new_mjaj);
                vm.in_edit_focus = 'new_mjaj';
            } else {
                $rootScope.critical_errors = 'There is already a new MjAj!';
            }
        }
        
        function deleteMjAj() {
            vm.error_message = '';
            var delete_promises = [];
            angular.forEach(vm.selected_mjajs, function (mjaj_id) {
                delete_promises.push(MjAjsService.deleteMjAj(mjaj_id));
            });
            $q.all(delete_promises)
                .then(function () {
                    synchronizeMjAjs();
                    vm.selected_mjajs.length = 0;
                }).catch(function (data, status, headers, config) {
                if (data.status == 500) {
                    $rootScope.critical_errors = 'Some mjajs is still used by other saveStates!';
                    synchronizeMjAjs();
                }
            })
        }

        function modifyMjAj(mjaj) {
            vm.error_message = '';
            if (!mjaj.mj) {
                $rootScope.critical_errors = 'Error: mj should Not be empty!';
                return
            }
            if (!mjaj.aj) {
                mjaj.aj = '';
            }
            if (mjaj.id == 'new_mjaj') {
                MjAjsService.addMjAjToProject(vm.projectId, mjaj.mj, mjaj.aj)
                    .then(function (data) {
                        synchronizeMjAjs();
                        vm.in_edit_focus = null;
                        vm.error_message = '';
                    }).catch(function (data) {
                    if (data.status == 400) {
                        $rootScope.critical_errors = 'same combination exists already in project!'
                    }
                })
            } else {
                MjAjsService.editMjAj(mjaj, mjaj.mj, mjaj.aj)
                    .then(function (data) {
                        mjaj.id = data.id;
                        mjaj.mj = data.mj;
                        mjaj.aj = data.aj;
                        vm.in_edit_focus = null;
                    }).catch(function (data, status, headers, config) {
                })
            }
        }
        
        function resetSelection() {
            vm.selected_mjajs.length = 0;
            vm.error_message = '';
        }
        
        function switchEditMode(mjaj) {
            if (vm.in_edit_focus === mjaj.id) {
                vm.in_edit_focus = null;
                vm.copy_in_edit_mjaj = null;
            } else {
                vm.in_edit_focus = mjaj.id;
                vm.copy_in_edit_mjaj = angular.copy(mjaj);
            }
        }

        function synchronizeMjAjs() {
            MjAjsService.getMjAjsByProject(vm.projectId)
                .then(function (data) {
                    vm.mjajs = data;
                })
        }
        
        function toggleSelection(mjaj_id) {
            var idx = vm.selected_mjajs.indexOf(mjaj_id);
            // is currently selected
            if (idx > -1) {
                vm.selected_mjajs.splice(idx, 1);
            }
            // is newly selected
            else {
                vm.selected_mjajs.push(mjaj_id);
            }
        }
        
        function undoEdit(mjaj) {
            vm.error_message = '';
            if (mjaj.id == 'new_mjaj') {
                vm.mjajs.splice(0, 1);
            } else {
                mjaj.mj = vm.copy_in_edit_mjaj.mj;
                mjaj.aj = vm.copy_in_edit_mjaj.aj;
                vm.copy_in_edit_mjaj = null;
            }
        }
    }
})();
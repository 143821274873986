(function () {
    'use strict';
    angular
        .module('myApp')
        .directive('slidePanel', ['$document', function ($document) {
            return {
                restrict: 'C',
                scope: {
                    show: '=',
                    clickOutside: '@'
                },
                compile: function (element, attr) {
                    var contents = element.html();
                    element.html('<div class="slide_content" style="margin:0 !important; padding:0 !important" >' + contents + '</div>');
                    return function postLink(scope, element, attrs) {
                        var isOpen = false;
                        attrs.duration = (!attrs.duration) ? '1' : attrs.duration;
                        attrs.easing = (!attrs.easing) ? 'ease-out' : attrs.easing;
                        attrs.expanded = true;
                        element.css({
                            'overflow': 'hidden',
                            'height': '0px',
                            'transitionProperty': 'height',
                            'transitionDuration': attrs.duration,
                            'transitionTimingFunction': attrs.easing
                        });

                        var target = document.querySelector("#" + attrs.id);
                        scope.$watch('show', function () {
                            var content = target.querySelector('.slide_content');
                            if (scope.show) {
                                if (scope.clickOutside) {
                                    $document.on('click', clickOnBody);
                                }
                                content.style.border = '1px solid rgba(0,0,0,0)';
                                if (attrs.height) {
                                    var div_height = attrs.height;
                                } else {
                                    var div_height = content.clientHeight + 'px';
                                }
                                content.style.border = 0;
                                target.style.height = div_height;
                            } else {
                                if (scope.clickOutside) {
                                    $document.off('click', clickOnBody);
                                }
                                target.style.height = '0px';
                                isOpen = false;
                            }
                            attrs.expanded = !attrs.expanded;
                        });

                        function clickOnBody(event) {
                            var content = target.querySelector('.slide_content');
                            if (content.contains(event.target) || target == event.target || !isOpen) {
                                if (scope.show) {
                                    isOpen = true;
                                }
                            } else {
                                scope.show = false;
                                isOpen = false;
                                scope.$apply();
                            }
                        }
                    };
                }
            };
        }])
})();

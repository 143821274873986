(function () {
    'use strict';

    angular.module('myApp.resourcedetails')
        .controller('ResourceDetailsController', ResourceDetailsController);

    ResourceDetailsController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$stateParams',
        '$timeout',
        'CobaResourceService',
        'current_resource',
        'project_info',
        'UiEffectService',
        '$window'
    ];
    function ResourceDetailsController($q,
                                       $rootScope,
                                       $scope,
                                       $stateParams,
                                       $timeout,
                                       CobaResourceService,
                                       current_resource,
                                       project_info,
                                       UiEffectService,
                                       $window) {

        var vm = this;

        vm.back_link_path = '';
        vm.current_detail_information = '';
        vm.current_version = null;
        vm.in_edit_focus = -1;
        vm.selected_update_cobafiles = null;
        vm.current_version_status = 'PREPARING'
        vm.permissions = null;
        vm.current_request_promise = null;

        vm.changeCobaResourceName = changeCobaResourceName;
        vm.downloadCobaFile = downloadCobaFile;
        vm.switchEditMode = switchEditMode;
        vm.updateCobaResource = updateCobaResource;
        vm.updateDetailInformation = updateDetailInformation;
        vm.undoEdit = undoEdit;
        activate();

        $scope.$on("$destroy", function handleDestroyEvent() {
                       vm.current_version = null;
                       if (vm.current_request_promise) {
                            $timeout.cancel(vm.current_request_promise);
                       }

                    }
                );

        function activate() {
            vm.project_name = $stateParams.projectname;
            vm.project_id = $stateParams.projectid;
            vm.resource_id = $stateParams.resourceid;
            vm.back_link_path = '/projectdetails/' + vm.project_name + '/' + vm.project_id;
            vm.permissions = project_info.permissions;
            updateControllerData(current_resource);
            $rootScope.currentNaviAction = 'projects';
        }

        function changeCobaResourceName() {
            var new_resource_name = vm.resource_name.trim();
            if (new_resource_name && new_resource_name != vm.current_resource.resource_name) {
                CobaResourceService.updateCobaResourceInformation(
                    new_resource_name, vm.resource_id, vm.project_id)
                    .then(function (data, status, headers, config) {
                        vm.in_edit_focus = -1;
                        updateControllerData(data);
                    }).catch(function (data, status, headers, config) {
                    vm.resource_name = vm.current_resource.resource_name;
                });
            }
        }
        
        function downloadCobaFile() {
            $window.location.href = CobaResourceService.getDownloadLinkOfCobaFile(vm.current_version);
        }

        function getPrepareStatusOfResourceVersion(resource_version_Id){
            var defer = $q.defer();
            var target_version_id = resource_version_Id;
            (function fetchData(){
                CobaResourceService.getCoBaResourceVersionStatusById(resource_version_Id).then(
                function(result){
                    if(result.status === 'STARTED'||result.status === 'PENDING'){
                        if (target_version_id === vm.current_version){
                            vm.current_request_promise = $timeout(fetchData, 5000);
                            defer.notify(result);}
                        else {
                            defer.reject('cancelled');
                        }
                    }else if(result.status === 'FAILURE' || result.status === 'SUCCESS' || result.status === 'MISSING_IN_V2'){
                        defer.resolve(result);
                    }
                }, function(err){
                    defer.reject(err);
                })
            })()

            return defer.promise;
            }

        function updateDetailInformation(){
            var information = {};
            angular.forEach(vm.current_resource.coba_resource_versions, function (resource_version) {
                if(resource_version.id == vm.current_version) {
                    information.file_name = resource_version.filename;
                    information.upload_time = resource_version.created;
                    information.resource_version_id = resource_version.id;
                }
            });
            vm.current_detail_information = JSON.stringify(information, undefined, 4);
            getPrepareStatusOfResourceVersion(vm.current_version).then(
                function(final_data){
                    vm.current_request_promise = null;
                    vm.current_version_status = final_data.status;
                },
                function(err){
                    vm.current_request_promise = null;
                },
                function(notify_data){
                    vm.current_version_status = 'PREPARING';
                })
        }

        function updateControllerData(resource) {
            vm.current_resource = resource;
            vm.resource_name = resource.resource_name;
            vm.current_version = resource.latest_version_id;
            vm.current_version_status = 'PREPARING'
            updateDetailInformation();
        }

        function switchEditMode() {
            if (vm.in_edit_focus == 'resourcename') {
                vm.in_edit_focus = -1;
            } else {
                vm.in_edit_focus = 'resourcename';
                UiEffectService.focus('change-resource-name');
            }
        }

        function undoEdit() {
            vm.resource_name = vm.current_resource.resource_name;
            vm.selected_update_cobafiles = null;
        }

        function updateCobaResource(newFile) {
            var file = newFile;
            CobaResourceService.updateCobaResourceFile(vm.resource_name, vm.resource_id, vm.project_id, file)
                .then(function (data, status, headers, config) {
                    vm.selected_update_cobafiles = null;
                    updateControllerData(data);
                })
                .catch(function (e) {
                });
        }
    }
})();
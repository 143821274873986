(function () {
    'use strict';

    angular
        .module('myApp')
        .factory('ProjectDataService', ProjectDataService);

    ProjectDataService.$inject = ['$http', '$q', 'baseProjectsUrl'];
    function ProjectDataService($http, $q, baseProjectsUrl) {
        var service = {};
        service.editProjectMembers = editProjectMembers;
        service.getProjectMembers = getProjectMembers;
        service.getProjectsInfo = getProjectsInfo;
        service.getProjectsDetailsInfo = getProjectsDetailsInfo;
        service.deleteProject = deleteProject;
        service.addNewProject = addNewProject;
        service.updateProject = updateProject;
        service.currentProjectId = -1;

        return service;

        function editProjectMembers(projectId, user_email, user_permissions) {
            var json_data = JSON.stringify({"user": user_email, "permissions": user_permissions});
            return $http.post(baseProjectsUrl + projectId + '/set-user-permissions-of-project/',json_data)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function exceptionHandle(e) {
            return $q.reject(e);
        }

        function getProjectsInfo() {
            return $http.get(baseProjectsUrl)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function getProjectMembers(projectId) {
            return $http.get(baseProjectsUrl + projectId + '/members/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }


        function getProjectsDetailsInfo(projectId) {
            return $http.get(baseProjectsUrl + projectId + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
        
        function deleteProject(projectId) {
            return $http.delete(baseProjectsUrl + projectId + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
        
        function addNewProject(newProjectInfo) {
            var json_data = JSON.stringify(newProjectInfo);
            return $http.post(baseProjectsUrl, json_data)
                .then(function (data, status, headers, config) {
                    var project = data.data;
                    project.permissions = {read: true, write: true, maintain: true};
                    return project;
                })
                .catch(exceptionHandle);
        }
        
        function updateProject(project) {
            //currently only the name of project will be updated, this should be extended
            var json_data = JSON.stringify({name: project.name, code: project.code});
            return $http.put(baseProjectsUrl + project.id + '/', json_data)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
    }
})();
(function () {
    'use strict';

    angular.module('myApp.resourcedetails', ['ui.router'])

        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('resourcedetails', {
                    url: '/projectdetails/:projectname/:projectid/resourcedetails/:resourceid',
                    templateUrl: 'resourcedetails/resourcedetails.html',
                    controller: 'ResourceDetailsController',
                    controllerAs: 'vm',
                    resolve: {
                        project_info:['$stateParams', 'ProjectDataService',
                            function ($stateParams, ProjectDataService) {
                                return ProjectDataService.getProjectsDetailsInfo($stateParams.projectid);
                            }],
                        current_resource: ['$stateParams', 'CobaResourceService',
                            function ($stateParams, CobaResourceService) {
                                return CobaResourceService.getCobaResourcesById($stateParams.resourceid);
                            }]
                    }
                })
        }]);
})();
(function () {
    'use strict';

    angular
        .module('myApp')
        .factory('SavedStatesCategoryService', SavedStatesCategoryService);

    SavedStatesCategoryService.$inject = ['$http', '$q', 'baseSavedStatesCategoryUrl'];
    function SavedStatesCategoryService($http, $q, baseSavedStatesCategoryUrl) {
        var service = {};
        service.addCategoryToProject = addCategoryToProject;
        service.deleteCategory = deleteCategory;
        service.editCategory = editCategory;
        service.getCategoriesByProject = getCategoriesByProject;

        return service;

        function exceptionHandle(e) {
            return $q.reject(e);
        }

        function addCategoryToProject(project, name) {
            var new_category = JSON.stringify({
                'project': project,
                'name': name
            });
            return $http.post(baseSavedStatesCategoryUrl, new_category)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function deleteCategory(category_id) {
            return $http.delete(baseSavedStatesCategoryUrl + category_id + '/')
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function editCategory(category, new_name) {
            var changed_category = JSON.stringify({
                'project': category.project,
                'name': new_name
            });
            return $http.put(baseSavedStatesCategoryUrl + category.id + '/', changed_category)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }

        function getCategoriesByProject(projectId) {
            return $http.get(baseSavedStatesCategoryUrl + '?project_id=' + projectId)
                .then(function (data, status, headers, config) {
                    return data.data;
                })
                .catch(exceptionHandle);
        }
    }
})();
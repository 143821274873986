(function () {

    'use strict';

    angular
        .module('myApp.home')
        .controller('HomeController', HomeController);

    HomeController.$inject = [
        '$q',
        '$location',
        'AuthenticationService',
        'FlashService',
        '$log',
        '$http',
        'UserPersistenceService',
        'AccountProfileService',
        '$rootScope'
    ];
    function HomeController(
        $q,
        $location,
        AuthenticationService,
        FlashService,
        $log,
        $http,
        UserPersistenceService,
        AccountProfileService,
        $rootScope
    ){

        var vm = this;

        vm.processing = false;
        vm.signup_successful = false;
        vm.show_signin = true;

        vm.login = login;
        vm.send_signup_request = send_signup_request;
        vm.validate_signup = validate_signup;
        vm.on_signup_clicked = on_signup_clicked;


        (function initController() {
            if ($rootScope.loggedIn && !$rootScope.errors) {
                $location.path('/projects')
            }
        })();

        /* User presses signup button in form */
        function on_signup_clicked() {
            vm.processing = true;

            // validation failed?
            if(vm.validate_signup() == false){
                vm.processing = false;
                return null;
            };

            // send register email
            vm.send_signup_request();
            vm.processing = false;
        };

        /* validation in frontend */
        function validate_signup() {
            // currently no special validation beyond what the form does
            // automatically
            return true;
        };

        /* send email */
        function send_signup_request() {

            var on_success = function (data, status, headers, config) {
                $log.debug("signup successful");
                vm.signup_successful = true;
            };

            var on_error = function (data, status, headers, config) {
                $log.debug("signup failed");
            };

            var data = {
                "company": vm.company,
                "first_name": vm.first_name,
                "last_name": vm.last_name,
                "email_address": vm.email_address,
                "phone_number": vm.phone_number,
            };

            $http({
                url: "/frontend/signup_request",
                method: "POST",
                data: JSON.stringify(data),
                headers: {"Content-Type": "application/json"},
            })
                .then(on_success, on_error);
        };

        function login() {
            vm.dataLoading = true;
            AuthenticationService.Login(vm.email, vm.password, function (response) {
                if (response.success) {
                    var user_data = response.data;
                    AuthenticationService.SetCredentials(vm.email, vm.password);
                    $q.all([AccountProfileService.getUserAccountProfile(user_data.user_id), AccountProfileService.getUserAvatar(user_data.user_id)]).then(function (multi_response) {
                        var profile_response = multi_response[0];
                        var display_name = profile_response.first_name + ' ' + profile_response.last_name;
                        var avatar_response = multi_response[1];
                        UserPersistenceService.setUserPersistence(vm.email, user_data.user_id, user_data.user_type, display_name, avatar_response, null);
                        $rootScope.user_persist_data = UserPersistenceService.getUserPersistence();
                        $location.path('/projects');
                    })
                } else {
                    FlashService.Error(response.message);
                    vm.dataLoading = false;
                }
                $rootScope.critical_errors = '';
            });
        };
    }

})();
(function () {
    'use strict';

    angular
        .module('myApp')
        .constant('baseAccountUrl', '/frontend/users/')
        .constant('baseTokenUrl', '/frontend/token/')
        .constant('baseAvatarUrl', '/frontend/avatar/')
        .constant('defaultAvatarPath', './static/pics/account.svg')
        .constant('defaultHoverPicPath', './static/pics/changepromt.png')
        .constant('baseProjectsUrl', '/frontend/projects/')
        .constant('baseResourceUrl', '/frontend/coba_resources/')
        .constant('baseResourceVersionUrl', '/frontend/coba_resource_versions/')
        .constant('baseMultiLevelBundleUrl', '/frontend/multi_level_bundles/')
        .constant('baseSavedStatesUrl', '/frontend/saved_states/')
        .constant('baseMarketsUrl', '/frontend/markets/')
        .constant('baseMjAjsUrl', '/frontend/mjajs/')
        .constant('baseSavedStatesCategoryUrl', '/frontend/saved_states_categories/')
        .constant('baseSavedStateToCategoryUrl', '/frontend/saved_state_to_categories/')
})();
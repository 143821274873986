(function () {
    'use strict';
    angular
        .module('myApp')
        .directive('cwsModalDialog', modalDialog);
    function modalDialog() {
        return {
            restrict: 'E',
            scope: {
                show: '=',
                okHandler: '&',
                cancelHandler: '&'
            },
            replace: true,
            transclude: true,
            link: function (scope, element, attrs) {
                scope.dialogStyle = {};
                if (attrs.width)
                    scope.dialogStyle.width = attrs.width;
                if (attrs.height)
                    scope.dialogStyle.height = attrs.height;
                scope.hideModal = function () {
                    scope.show = false;
                };
            },
            templateUrl: function (tElement, tAttrs) {
                if (tAttrs.type == 'confirm') {
                    return 'components/dialog.confirm.html';
                }
                else if (tAttrs.type == 'message') {
                    return 'components/dialog.message.html';
                }
                else if (tAttrs.type == 'avatar') {
                    return 'components/dialog.avatar.html';
                }
            }
        };
    }
})();

(function () {
    'use strict';

    angular.module('myApp.projectdetails')
        .controller('ProjectSavedStatesController', ProjectSavedStatesController);

    ProjectSavedStatesController.$inject = [
        '$location',
        '$q',
        '$rootScope',
        '$anchorScroll',
        '$stateParams',
        'SavedStatesService',
        'all_markets',
        'all_mjajs',
        'all_categories',
        'all_saved_states',
        'project_info'];
    function ProjectSavedStatesController(
        $location,
        $q,
        $rootScope,
        $anchorScroll,
        $stateParams,
        SavedStatesService,
        all_markets,
        all_mjajs,
        all_categories,
        all_saved_states,
        project_info) {

        var vm = this;
        vm.copy_in_edit_saved_state = null;
        vm.error_message = '';
        vm.in_edit_focus = null;
        vm.saved_states = null;
        vm.selected_states = [];
        vm.show_delete_dialog = false;

        //filter related variables
        vm.all_selected_filters = [];
        vm.filters = {};
        vm.name_filter = '';
        vm.codes_filter = '';
        vm.market_filter = '';
        vm.market_filter_place_holder = 'any market';
        vm.mjaj_filter_place_holder = 'any mjaj';
        vm.category_filter_place_holder = 'any category';
        vm.mjaj_filter = '';
        vm.categories_filter = '';

        //pagination related variables
        vm.current_page = 1;
        vm.next = null;
        vm.page_size = 15;
        vm.page_num_array = [];
        vm.previous = null;
        vm.total_num_of_saved_states = 0;
        vm.total_num_of_pages = 0;
        vm.max_showable_page_num = 5;
        vm.page_num_offset = 1;

        vm.addNewEmptySavedState = addNewEmptySavedState;
        vm.add_selected_filter_to_all = add_selected_filter_to_all;
        vm.deleteSavedState = deleteSavedState;
        vm.filterSavedState = filterSavedState;
        vm.goFirstPage = goFirstPage;
        vm.goLastPage = goLastPage;
        vm.goNextPage = goNextPage;
        vm.goPreviousPage = goPreviousPage;
        vm.goSelectPage = goSelectPage;
        vm.modifySavedState = modifySavedState;
        vm.resetFilters = resetFilters;
        vm.resetSelection = resetSelection;
        vm.remove_selected_filter_from_all = remove_selected_filter_from_all;
        vm.switchEditMode = switchEditMode;
        vm.toggleSelection = toggleSelection;
        vm.undoEdit = undoEdit;

        activate();

        function activate() {
            updateData(all_saved_states);
            vm.categories = all_categories;
            angular.forEach(vm.categories, function (cate) {
                cate.display_name = cate.name;
                cate.type = 'category';
            });
            vm.markets = all_markets;
            angular.forEach(vm.markets, function (market) {
                market.display_name = market.code + '-' + market.name;
                market.type = 'market';
            });
            vm.mjajs = all_mjajs;
            angular.forEach(vm.mjajs, function (mjaj) {
                mjaj.display_name = mjaj.mj + ',' + mjaj.aj;
                mjaj.type = 'mjaj';
            });
            vm.available_categories = angular.copy(vm.categories);
            vm.available_markets = angular.copy(vm.markets);
            vm.available_mjajs = angular.copy(vm.mjajs);
            vm.projectId = $stateParams.projectid;
            vm.permissions = project_info.permissions;
            retrieveFilterParamsFromUrl();
            if ($stateParams.page) {
                vm.goSelectPage($stateParams.page)
            } else if (vm.name_filter || vm.codes_filter || vm.all_selected_filters.length>0) {
                vm.filterSavedState();
            }
        }

        function addNewEmptySavedState() {
            if (vm.in_edit_focus !== 'new_saved_state') {
                var new_saved_state = {
                    id: 'new_saved_state',
                    option_codes:'',
                    market:0,
                    mjaj:0
                };
                vm.saved_states.splice(0, 0, new_saved_state);
                vm.in_edit_focus = 'new_saved_state';
            }
        }
        
        function add_selected_filter_to_all(filter) {
            vm.all_selected_filters.push(filter);
            var index;
            if (filter.type === 'mjaj') {
                vm.mjaj_filter = '';
                index = vm.available_mjajs.indexOf(filter);
                if (index > -1) {
                    vm.available_mjajs.splice(index, 1);
                }
                update_filter_place_holder('mjaj');
            }
            if (filter.type === 'market') {
                vm.market_filter = '';
                index = vm.available_markets.indexOf(filter);
                if (index > -1) {
                    vm.available_markets.splice(index, 1);
                }
                update_filter_place_holder('market');
            }
            if (filter.type === 'category') {
                vm.categories_filter = '';
                index = vm.available_categories.indexOf(filter);
                if (index > -1) {
                    vm.available_categories.splice(index, 1);
                }
                update_filter_place_holder('category');
            }
        }

        function backUpInEditSavedState(saved_state) {
            vm.copy_in_edit_saved_state = angular.copy(saved_state);
        }

        function deleteSavedState() {
            var delete_promises = [];
            angular.forEach(vm.selected_states, function (saved_state_id) {
                delete_promises.push(SavedStatesService.deleteSavedState(saved_state_id));
            });
            $q.all(delete_promises)
                .then(function () {
                    synchronizeSavedStates();
                    vm.selected_states.length = 0;
                }).catch(function (data, status, headers, config) {
            })
        }

        function filterSavedState() {
            vm.filters = {};
            if (vm.name_filter) {
                vm.filters.name = vm.name_filter;
            }
            if (vm.codes_filter) {
                vm.filters.option_codes = [vm.codes_filter];
            }
            vm.filters.markets = [];
            vm.filters.mjajs = [];
            vm.filters.categories = [];
            angular.forEach(vm.all_selected_filters, function (filter) {
                if (filter.type === 'mjaj') {
                    vm.filters.mjajs.push(filter.id)
                } else if (filter.type === 'market') {
                    vm.filters.markets.push(filter.id);
                } else if (filter.type === 'category') {
                    vm.filters.categories.push(filter.id);
                }
            });
            SavedStatesService.getSavedStatesByProject(vm.projectId, vm.page_size, vm.filters)
                .then(function (data) {
                    updateData(data);
                    updatePageNumOffset();
                    updateSearchParamsInUrl();
                })
        }

        function getFilterFromFiltersByType(filters_of_same_type, filter_id) {
            var filter_id = filter_id*1;
            for (var i=0; i< filters_of_same_type.length; i++) {
                if (filters_of_same_type[i].id == filter_id) {
                    return filters_of_same_type[i];
                }
            }
            return null;
        }

        function goFirstPage() {
            SavedStatesService.getSavedStatesByProject(vm.projectId, vm.page_size, vm.filters, 1)
                .then(function (data) {
                    updateData(data);
                    vm.current_page = 1;
                    updatePageNumInUrl();
                    updatePageNumOffset();
                    $anchorScroll();
                })
        }


        function goLastPage() {
            SavedStatesService.getSavedStatesByProject(vm.projectId, vm.page_size, vm.filters, vm.total_num_of_pages)
                .then(function (data) {
                    updateData(data);
                    vm.current_page = vm.total_num_of_pages;
                    updatePageNumInUrl();
                    updatePageNumOffset();
                    $anchorScroll();
                })
        }

        function goNextPage() {
            SavedStatesService.getSavedStatesByParamUrl(vm.next)
                .then(function (data) {
                    updateData(data);
                    vm.current_page += 1;
                    updatePageNumInUrl();
                    updatePageNumOffset();
                    $anchorScroll();
                })
        }

        function goPreviousPage() {
            SavedStatesService.getSavedStatesByParamUrl(vm.previous)
                .then(function (data) {
                    updateData(data);
                    vm.current_page -= 1;
                    updatePageNumInUrl();
                    updatePageNumOffset();
                    $anchorScroll();
                })
        }

        function goSelectPage(page_num) {
            SavedStatesService.getSavedStatesByProject(vm.projectId, vm.page_size, vm.filters, page_num)
                .then(function (data) {
                    vm.current_page = page_num;
                    updatePageNumInUrl();
                    updateData(data);
                    updatePageNumOffset();
                    $anchorScroll();
                })
        }

        function modifySavedState(saved_state) {
            vm.error_message = '';
            var errors = [];
            angular.forEach(['name', 'option_codes', 'market', 'mjaj'], function (att) {
                if (!saved_state[att]) {
                    errors.push(att);
                }
            });
            if (errors.length>0) {
                $rootScope.critical_errors = 'Error: ' + errors.join(',') + ' should Not be empty!';
                return
            }
            var cate_ids = [];
            angular.forEach(saved_state.categories, function (cate) {
                cate_ids.push(cate.id);
            });
            if (saved_state.id == 'new_saved_state') {
                SavedStatesService.addSavedStateToProject(
                    vm.projectId, saved_state.name, saved_state.option_codes, saved_state.market, saved_state.mjaj, cate_ids)
                    .then(function (data) {
                        synchronizeSavedStates();
                        vm.in_edit_focus = null;
                        vm.error_message = '';
                        vm.copy_in_edit_saved_state = null;
                    }).catch(function (data) {
                    if (data.status == 400) {
                        $rootScope.critical_errors = 'Same name exists already in project!'
                    }
                })
            } else {
                SavedStatesService.editSavedState(saved_state, cate_ids)
                    .then(function (data) {
                        vm.in_edit_focus = null;
                        vm.copy_in_edit_saved_state = null;
                    }).catch(function (data, status, headers, config) {

                })
            }
        }

        function remove_selected_filter_from_all(filter) {
            if (filter.type === 'mjaj') {
                vm.available_mjajs = update_new_available_filters(filter, vm.mjajs);
                update_filter_place_holder('mjaj');
            }
            if (filter.type === 'category') {
                vm.available_categories = update_new_available_filters(filter, vm.categories);
                update_filter_place_holder('category');
            }
            if (filter.type === 'market') {
                vm.available_markets = update_new_available_filters(filter, vm.markets);
                update_filter_place_holder('market');
            }
        }

        function resetFilters() {
            vm.name_filter = '';
            vm.codes_filter = '';
            vm.market_filter = '';
            vm.mjaj_filter = '';
            vm.categories_filter = '';
            vm.all_selected_filters = [];
            vm.available_categories = angular.copy(vm.categories);
            vm.available_markets = angular.copy(vm.markets);
            vm.available_mjajs = angular.copy(vm.mjajs);
            update_filter_place_holder('category');
            update_filter_place_holder('market');
            update_filter_place_holder('mjaj');
            vm.filterSavedState();
        }
        function resetSelection() {
            vm.selected_states.length = 0;
        }

        function retrieveFilterParamsFromUrl() {
            if ($stateParams.option_codes) {
                vm.codes_filter = $stateParams.option_codes;
            }
            if ($stateParams.name) {
                vm.name_filter = $stateParams.name;
            }
            if ($stateParams.page) {
                vm.current_page = $stateParams.page * 1;
            }
            if ($stateParams.market) {
                var market_ids = $stateParams.market.split(',');
                angular.forEach(market_ids, function (market_id) {
                    var filter = getFilterFromFiltersByType(vm.available_markets, market_id);
                    if (filter) {
                        vm.add_selected_filter_to_all(filter);
                    }
                });
            }
            if ($stateParams.mjaj) {
                var mjaj_ids = $stateParams.mjaj.split(',');
                angular.forEach(mjaj_ids, function (mjaj_id) {
                    var filter = getFilterFromFiltersByType(vm.available_mjajs, mjaj_id);
                    if (filter) {
                        vm.add_selected_filter_to_all(filter)
                    }
                });
            }
            if ($stateParams.categories) {
                var categories_ids;
                if (typeof $stateParams.categories === 'string') {
                    categories_ids = [$stateParams.categories];
                } else {
                    categories_ids = $stateParams.categories;
                }
                angular.forEach(categories_ids, function (category_id) {
                    var filter = getFilterFromFiltersByType(vm.available_categories, category_id);
                    if (filter) {
                        vm.add_selected_filter_to_all(filter)
                    }
                });
            }
        }

        function switchEditMode(saved_state) {
            if (vm.in_edit_focus === saved_state.id) {
                vm.in_edit_focus = null;
            } else {
                vm.in_edit_focus = saved_state.id;
                backUpInEditSavedState(saved_state);
            }
        }

        function synchronizeSavedStates() {
            SavedStatesService.getSavedStatesByProject(vm.projectId, vm.page_size, vm.filters)
                .then(function (data) {
                    updateData(data);
                })
        }

        function toggleSelection(state_id) {
            var idx = vm.selected_states.indexOf(state_id);
            // is currently selected
            if (idx > -1) {
                vm.selected_states.splice(idx, 1);
            }
            // is newly selected
            else {
                vm.selected_states.push(state_id);
            }
        }

        function update_new_available_filters(filter_to_remove, all_filters_by_type) {
            var all_used_filters_id = [];
            for (var i = 0; i < vm.all_selected_filters.length; i++) {
                if (vm.all_selected_filters[i].type === filter_to_remove.type) {
                    if (vm.all_selected_filters[i].id == filter_to_remove.id)
                        vm.all_selected_filters.splice(i, 1);
                    else {
                        all_used_filters_id.push(vm.all_selected_filters[i].id);
                    }
                }
            }
            var new_available_filters = [];
            for (var j = 0; j < all_filters_by_type.length; j++) {
                if (all_used_filters_id.indexOf(all_filters_by_type[j].id) < 0) {
                    new_available_filters.push(all_filters_by_type[j]);
                }
            }
            return new_available_filters
        }

        function update_filter_place_holder(filter_type) {
            var used_filter_num, property_name;
            if (filter_type==='mjaj'){
                used_filter_num = vm.mjajs.length - vm.available_mjajs.length;
                property_name = 'mjaj_filter_place_holder';
            }
            else if (filter_type==='market'){
                used_filter_num = vm.markets.length - vm.available_markets.length;
                property_name = 'market_filter_place_holder';
            }
            else if (filter_type==='category'){
                used_filter_num = vm.categories.length - vm.available_categories.length;
                property_name = 'category_filter_place_holder';
            }
            if (used_filter_num == 0) {
                    vm[property_name] = 'any ' + filter_type;
                } else if (used_filter_num == 1) {
                    vm[property_name] = '1 '+ filter_type+ ' filter';
                } else {
                    vm[property_name] = used_filter_num.toString() +' '+ filter_type+ ' filters';
                }
        }


        function undoEdit(saved_state) {
            vm.error_message = '';
            if (saved_state.id == 'new_saved_state') {
                vm.saved_states.splice(0, 1);
                vm.copy_in_edit_saved_state = null;
            } else {
                saved_state.option_codes = vm.copy_in_edit_saved_state.option_codes;
                saved_state.name = vm.copy_in_edit_saved_state.name;
                saved_state.market = vm.copy_in_edit_saved_state.market;
                saved_state.mjaj = vm.copy_in_edit_saved_state.mjaj;
                saved_state.categories = vm.copy_in_edit_saved_state.categories;
                vm.copy_in_edit_saved_state = null;
            }
        }

        function updateData(new_data) {
            vm.saved_states = new_data.results;
            angular.forEach(vm.saved_states, function(state) {
                    state.category_ids = [];
                for (var i=0; i< state.categories.length;i++) {
                    state.category_ids.push(state.categories[i].id);}
                });
            if (new_data.next) {
                vm.next = new_data.next.split("?")[1];
            } else {
                vm.next = '';
            }
            if (new_data.previous) {
                vm.previous = new_data.previous.split("?")[1];
            } else {
                vm.previous = '';
            }
            vm.total_num_of_saved_states = new_data.count;
            vm.total_num_of_pages = Math.ceil(vm.total_num_of_saved_states / vm.page_size);
            if (vm.total_num_of_pages > vm.max_showable_page_num) {
                vm.page_num_array = new Array(vm.max_showable_page_num);
            } else {
                vm.page_num_array = new Array(vm.total_num_of_pages);
            }}

        function updatePageNumOffset() {
            var forward_lookup = Math.ceil(vm.page_num_array.length/2);
            var backward_lookup = Math.floor(vm.page_num_array.length/2);
            if (vm.current_page - forward_lookup < 0) {
                vm.page_num_offset = 1;
            } else if (vm.current_page + backward_lookup > vm.total_num_of_pages) {
                vm.page_num_offset = vm.total_num_of_pages - vm.page_num_array.length + 1;
            } else {
                vm.page_num_offset = vm.current_page - forward_lookup + 1;
            }
        }

        function updatePageNumInUrl() {
            if (vm.current_page>1) {
                $location.search('page', vm.current_page);
            } else {
                $location.search('page', null);
            }
        }
        function updateSearchParamsInUrl() {
            $location.search('page', null);
            if (vm.filters.name){
                $location.search('name', vm.filters.name);
            } else {
                $location.search('name', null);
            }
            if (vm.filters.option_codes){
                $location.search('option_codes', vm.filters.option_codes);
            } else {
                $location.search('option_codes', null);
            }
            if (vm.filters.mjajs&&vm.filters.mjajs.length>0){
                $location.search('mjaj', vm.filters.mjajs.join(','));
            } else {
                $location.search('mjaj', null);
            }
            if (vm.filters.markets&&vm.filters.markets.length>0){
                $location.search('market', vm.filters.markets.join(','));
            } else {
                $location.search('market', null);
            }
            if (vm.filters.categories&&vm.filters.categories.length>0) {
                $location.search('categories', vm.filters.categories);
            } else {
                $location.search('categories', null);
            }
        }
    }
})();
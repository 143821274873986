(function () {
    'use strict';

    angular
        .module('myApp')
        .factory('UserPersistenceService', UserPersistenceService);

    UserPersistenceService.$inject = ['$cookies', 'defaultAvatarPath'];
    function UserPersistenceService($cookies, defaultAvatarPath) {
        var service = {};
        service.clearUserPersistence = clearUserPersistence;
        service.getUserPersistence = getUserPersistence;
        service.setUserPersistence = setUserPersistence;
        service.updateUserAvatar = updateUserAvatar;

        return service;

        function getUserPersistence() {
            var user_persistence_data = $cookies.getObject('user_persist');
            if (user_persistence_data) {
                user_persistence_data.avatar = localStorage.getItem('avatar');
            }
            return user_persistence_data;
        }

        function setUserPersistence(user_email, user_id, user_type, display_name, avatar, expire_date) {
            if (!avatar) {
                avatar = defaultAvatarPath;
            }
            var user_persist = {
                'user_type': user_type, 'user_id': user_id, 'user_email': user_email, 'display_name': display_name
            };
            if (expire_date) {
                $cookies.putObject('user_persist', user_persist, {expires: expire_date});
            } else {
                $cookies.putObject('user_persist', user_persist);
            }
            updateUserAvatar(avatar);
        }

        function updateUserAvatar(new_avatar) {
            localStorage.setItem('avatar', new_avatar);
        }

        function clearUserPersistence() {
            $cookies.remove('user_persist');
            localStorage.removeItem('avatar');
        }
    }
})();